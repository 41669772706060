import React, { useState } from "react";
import { Switch, Button, Tag, message, DatePicker } from "antd";
import {
  defaultTimeout,
  onChangeHour,
  onChangeHour2,
} from "./ReturdefaultTime";
import "./index.css";

const { RangePicker } = DatePicker;

export default function FormHorario(props: any) {
  const { data, actualizarRestaurant, refetch } = props;
  const [loading, setloading] = useState(false);

  //LUNES

  const [MondayOpen, setMondayOpen] = useState(data.schedule.Monday.isOpen);

  //Open

  const [MondayOpenHour1, setMondayOpenHour1] = useState(
    data.schedule.Monday.openHour1
  );

  const [MondayOpenHour2, setMondayOpenHour2] = useState(
    data.schedule.Monday.openHour2
  );

  const [MondayOpenMinute1, setMondayOpenMinute1] = useState(
    data.schedule.Monday.openMinute1
  );

  const [MondayOpenMinute2, setMondayOpenMinute2] = useState(
    data.schedule.Monday.openMinute2
  );

  //Close

  const [MondayCloseHour1, setMondayCloseHour1] = useState(
    data.schedule.Monday.closetHour1
  );
  const [MondayCloseHour2, setMondayCloseHour2] = useState(
    data.schedule.Monday.closetHour2
  );

  const [MondayCloseMinute1, setMondayCloseMinute1] = useState(
    data.schedule.Monday.closetMinute1
  );

  const [MondayCloseMinute2, setMondayCloseMinute2] = useState(
    data.schedule.Monday.closetMinute2
  );

  function onChangeMonday(checked: boolean) {
    setMondayOpen(checked);
  }

  //END

  //MARTES

  const [TuesdayOpen, setTuesdayOpen] = useState(data.schedule.Tuesday.isOpen);

  //Open

  const [TuesdayOpenHour1, setTuesdayOpenHour1] = useState(
    data.schedule.Tuesday.openHour1
  );

  const [TuesdayOpenHour2, setTuesdayOpenHour2] = useState(
    data.schedule.Tuesday.openHour2
  );

  const [TuesdayOpenMinute1, setTuesdayOpenMinute1] = useState(
    data.schedule.Tuesday.openMinute1
  );

  const [TuesdayOpenMinute2, setTuesdayOpenMinute2] = useState(
    data.schedule.Tuesday.openMinute2
  );

  //Close

  const [TuesdayCloseHour1, setTuesdayCloseHour1] = useState(
    data.schedule.Tuesday.closetHour1
  );
  const [TuesdayCloseHour2, setTuesdayCloseHour2] = useState(
    data.schedule.Tuesday.closetHour2
  );

  const [TuesdayCloseMinute1, setTuesdayCloseMinute1] = useState(
    data.schedule.Tuesday.closetMinute1
  );

  const [TuesdayCloseMinute2, setTuesdayCloseMinute2] = useState(
    data.schedule.Tuesday.closetMinute2
  );

  function onChangeTuesday(checked: boolean) {
    setTuesdayOpen(checked);
  }

  //END

  //MIERCOLES

  const [WednesdayOpen, setWednesdayOpen] = useState(
    data.schedule.Wednesday.isOpen
  );

  //Open

  const [WednesdayOpenHour1, setWednesdayOpenHour1] = useState(
    data.schedule.Wednesday.openHour1
  );

  const [WednesdayOpenHour2, setWednesdayOpenHour2] = useState(
    data.schedule.Wednesday.openHour2
  );

  const [WednesdayOpenMinute1, setWednesdayOpenMinute1] = useState(
    data.schedule.Wednesday.openMinute1
  );

  const [WednesdayOpenMinute2, setWednesdayOpenMinute2] = useState(
    data.schedule.Wednesday.openMinute2
  );

  //Close

  const [WednesdayCloseHour1, setWednesdayCloseHour1] = useState(
    data.schedule.Wednesday.closetHour1
  );
  const [WednesdayCloseHour2, setWednesdayCloseHour2] = useState(
    data.schedule.Wednesday.closetHour2
  );

  const [WednesdayCloseMinute1, setWednesdayCloseMinute1] = useState(
    data.schedule.Wednesday.closetMinute1
  );

  const [WednesdayCloseMinute2, setWednesdayCloseMinute2] = useState(
    data.schedule.Wednesday.closetMinute2
  );

  function onChangeWednesday(checked: boolean) {
    setWednesdayOpen(checked);
  }

  //END

  //JUEVES

  const [ThursdayOpen, setThursdayOpen] = useState(
    data.schedule.Thursday.isOpen
  );

  //Open

  const [ThursdayOpenHour1, setThursdayOpenHour1] = useState(
    data.schedule.Thursday.openHour1
  );

  const [ThursdayOpenHour2, setThursdayOpenHour2] = useState(
    data.schedule.Thursday.openHour2
  );

  const [ThursdayOpenMinute1, setThursdayOpenMinute1] = useState(
    data.schedule.Thursday.openMinute1
  );

  const [ThursdayOpenMinute2, setThursdayOpenMinute2] = useState(
    data.schedule.Thursday.openMinute2
  );

  //Close

  const [ThursdayCloseHour1, setThursdayCloseHour1] = useState(
    data.schedule.Thursday.closetHour1
  );
  const [ThursdayCloseHour2, setThursdayCloseHour2] = useState(
    data.schedule.Thursday.closetHour2
  );

  const [ThursdayCloseMinute1, setThursdayCloseMinute1] = useState(
    data.schedule.Thursday.closetMinute1
  );

  const [ThursdayCloseMinute2, setThursdayCloseMinute2] = useState(
    data.schedule.Thursday.closetMinute2
  );

  function onChangeThursday(checked: boolean) {
    setThursdayOpen(checked);
  }

  //END

  //VIERNES

  const [FridayOpen, setFridayOpen] = useState(data.schedule.Friday.isOpen);

  //Open

  const [FridayOpenHour1, setFridayOpenHour1] = useState(
    data.schedule.Friday.openHour1
  );

  const [FridayOpenHour2, setFridayOpenHour2] = useState(
    data.schedule.Friday.openHour2
  );

  const [FridayOpenMinute1, setFridayOpenMinute1] = useState(
    data.schedule.Friday.openMinute1
  );

  const [FridayOpenMinute2, setFridayOpenMinute2] = useState(
    data.schedule.Friday.openMinute2
  );

  //Close

  const [FridayCloseHour1, setFridayCloseHour1] = useState(
    data.schedule.Friday.closetHour1
  );
  const [FridayCloseHour2, setFridayCloseHour2] = useState(
    data.schedule.Friday.closetHour2
  );

  const [FridayCloseMinute1, setFridayCloseMinute1] = useState(
    data.schedule.Friday.closetMinute1
  );

  const [FridayCloseMinute2, setFridayCloseMinute2] = useState(
    data.schedule.Friday.closetMinute2
  );

  function onChangeFriday(checked: boolean) {
    setFridayOpen(checked);
  }

  //END

  //SÁBADO

  const [SaturdayOpen, setSaturdayOpen] = useState(
    data.schedule.Saturday.isOpen
  );

  //Open

  const [SaturdayOpenHour1, setSaturdayOpenHour1] = useState(
    data.schedule.Saturday.openHour1
  );

  const [SaturdayOpenHour2, setSaturdayOpenHour2] = useState(
    data.schedule.Saturday.openHour2
  );

  const [SaturdayOpenMinute1, setSaturdayOpenMinute1] = useState(
    data.schedule.Saturday.openMinute1
  );

  const [SaturdayOpenMinute2, setSaturdayOpenMinute2] = useState(
    data.schedule.Saturday.openMinute2
  );

  //Close

  const [SaturdayCloseHour1, setSaturdayCloseHour1] = useState(
    data.schedule.Saturday.closetHour1
  );
  const [SaturdayCloseHour2, setSaturdayCloseHour2] = useState(
    data.schedule.Saturday.closetHour2
  );

  const [SaturdayCloseMinute1, setSaturdayCloseMinute1] = useState(
    data.schedule.Saturday.closetMinute1
  );

  const [SaturdayCloseMinute2, setSaturdayCloseMinute2] = useState(
    data.schedule.Saturday.closetMinute2
  );

  function onChangeSaturday(checked: boolean) {
    setSaturdayOpen(checked);
  }

  //END

  //DOMINGO

  const [SundayOpen, setSundayOpen] = useState(data.schedule.Sunday.isOpen);

  //Open

  const [SundayOpenHour1, setSundayOpenHour1] = useState(
    data.schedule.Sunday.openHour1
  );

  const [SundayOpenHour2, setSundayOpenHour2] = useState(
    data.schedule.Sunday.openHour2
  );

  const [SundayOpenMinute1, setSundayOpenMinute1] = useState(
    data.schedule.Sunday.openMinute1
  );

  const [SundayOpenMinute2, setSundayOpenMinute2] = useState(
    data.schedule.Sunday.openMinute2
  );

  //Close

  const [SundayCloseHour1, setSundayCloseHour1] = useState(
    data.schedule.Sunday.closetHour1
  );
  const [SundayCloseHour2, setSundayCloseHour2] = useState(
    data.schedule.Sunday.closetHour2
  );

  const [SundayCloseMinute1, setSundayCloseMinute1] = useState(
    data.schedule.Sunday.closetMinute1
  );

  const [SundayCloseMinute2, setSundayCloseMinute2] = useState(
    data.schedule.Sunday.closetMinute2
  );

  function onChangeSunday(checked: boolean) {
    setSundayOpen(checked);
  }

  //END

  const input = {
    _id: data._id,
    schedule: {
      Monday: {
        day: "Lunes",
        isOpen: MondayOpen,
        openHour1: MondayOpenHour1,
        openHour2: MondayOpenHour2,
        openMinute1: MondayOpenMinute1,
        openMinute2: MondayOpenMinute2,
        closetHour1: MondayCloseHour1,
        closetHour2: MondayCloseHour2,
        closetMinute1: MondayCloseMinute1,
        closetMinute2: MondayCloseMinute2,
      },
      Tuesday: {
        day: "Martes",
        isOpen: TuesdayOpen,
        openHour1: TuesdayOpenHour1,
        openHour2: TuesdayOpenHour2,
        openMinute1: TuesdayOpenMinute1,
        openMinute2: TuesdayOpenMinute2,
        closetHour1: TuesdayCloseHour1,
        closetHour2: TuesdayCloseHour2,
        closetMinute1: TuesdayCloseMinute1,
        closetMinute2: TuesdayCloseMinute2,
      },
      Wednesday: {
        day: "Miércoles",
        isOpen: WednesdayOpen,
        openHour1: WednesdayOpenHour1,
        openHour2: WednesdayOpenHour2,
        openMinute1: WednesdayOpenMinute1,
        openMinute2: WednesdayOpenMinute2,
        closetHour1: WednesdayCloseHour1,
        closetHour2: WednesdayCloseHour2,
        closetMinute1: WednesdayCloseMinute1,
        closetMinute2: WednesdayCloseMinute2,
      },
      Thursday: {
        day: "Jueves",
        isOpen: ThursdayOpen,
        openHour1: ThursdayOpenHour1,
        openHour2: ThursdayOpenHour2,
        openMinute1: ThursdayOpenMinute1,
        openMinute2: ThursdayOpenMinute2,
        closetHour1: ThursdayCloseHour1,
        closetHour2: ThursdayCloseHour2,
        closetMinute1: ThursdayCloseMinute1,
        closetMinute2: ThursdayCloseMinute2,
      },
      Friday: {
        day: "Viernes",
        isOpen: FridayOpen,
        openHour1: FridayOpenHour1,
        openHour2: FridayOpenHour2,
        openMinute1: FridayOpenMinute1,
        openMinute2: FridayOpenMinute2,
        closetHour1: FridayCloseHour1,
        closetHour2: FridayCloseHour2,
        closetMinute1: FridayCloseMinute1,
        closetMinute2: FridayCloseMinute2,
      },
      Saturday: {
        day: "Sábado",
        isOpen: SaturdayOpen,
        openHour1: SaturdayOpenHour1,
        openHour2: SaturdayOpenHour2,
        openMinute1: SaturdayOpenMinute1,
        openMinute2: SaturdayOpenMinute2,
        closetHour1: SaturdayCloseHour1,
        closetHour2: SaturdayCloseHour2,
        closetMinute1: SaturdayCloseMinute1,
        closetMinute2: SaturdayCloseMinute2,
      },
      Sunday: {
        day: "Domingo",
        isOpen: SundayOpen,
        openHour1: SundayOpenHour1,
        openHour2: SundayOpenHour2,
        openMinute1: SundayOpenMinute1,
        openMinute2: SundayOpenMinute2,
        closetHour1: SundayCloseHour1,
        closetHour2: SundayCloseHour2,
        closetMinute1: SundayCloseMinute1,
        closetMinute2: SundayCloseMinute2,
      },
    },
  };

  const handleOk = () => {
    console.log(input);
    setloading(true);
    actualizarRestaurant({ variables: { input: input } })
      .then((res: any) => {
        if (res.data.actualizarRestaurant.success) {
          refetch();
          message.success("Horario actualizado");
          setloading(false);
        } else {
          message.warning("Algo salio mal intentalo de nuevo");
          setloading(false);
          refetch();
        }
      })
      .catch(() => {
        message.error("Error del sistema");
        setloading(false);
        refetch();
      });
  };

  return (
    <div className="horario_form">
      <div>
        <div className="day">
          <div style={{ marginTop: 10 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3 style={{ fontWeight: "bold" }}>
                Lunes{" "}
                {MondayOpen ? (
                  <Tag color="green">Abierto</Tag>
                ) : (
                  <Tag color="red">Cerrado</Tag>
                )}
              </h3>
              <Switch
                checked={MondayOpen}
                onChange={onChangeMonday}
                checkedChildren="Abierto"
                unCheckedChildren="Cerrado"
              />
            </div>
            <section>
              <div style={{ marginTop: 10 }}>
                <RangePicker
                  picker="time"
                  format="HH:mm"
                  onChange={(value) => {
                    onChangeHour(
                      value,
                      setMondayOpenHour1,
                      setMondayOpenMinute1,
                      setMondayCloseHour1,
                      setMondayCloseMinute1
                    );
                  }}
                  allowClear={false}
                  defaultValue={defaultTimeout(
                    MondayOpenHour1,
                    MondayOpenMinute1,
                    MondayCloseHour1,
                    MondayCloseMinute1
                  )}
                  style={{ width: 300 }}
                />
              </div>
              <div style={{ marginTop: 10 }}>
                <RangePicker
                  picker="time"
                  format="HH:mm"
                  onChange={(value) => {
                    onChangeHour2(
                      value,
                      setMondayOpenHour2,
                      setMondayOpenMinute2,
                      setMondayCloseHour2,
                      setMondayCloseMinute2
                    );
                  }}
                  style={{ width: 300 }}
                  defaultValue={
                    MondayOpenHour2
                      ? defaultTimeout(
                          MondayOpenHour2,
                          MondayOpenMinute2,
                          MondayCloseHour2,
                          MondayCloseMinute2
                        )
                      : null
                  }
                />
              </div>
            </section>
          </div>
        </div>

        {/* End */}

        {/* Martes */}

        <div className="day">
          <div style={{ marginTop: 10 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3 style={{ fontWeight: "bold" }}>
                Martes{" "}
                {TuesdayOpen ? (
                  <Tag color="green">
                    <span>Abierto</span>
                  </Tag>
                ) : (
                  <Tag color="red">
                    <span>Cerrado</span>
                  </Tag>
                )}
              </h3>
              <Switch
                checked={TuesdayOpen}
                onChange={onChangeTuesday}
                checkedChildren="Abierto"
                unCheckedChildren="Cerrado"
              />
            </div>

            <section>
              <div style={{ marginTop: 10 }}>
                <RangePicker
                  picker="time"
                  format="HH:mm"
                  onChange={(value) => {
                    onChangeHour(
                      value,
                      setTuesdayOpenHour1,
                      setTuesdayOpenMinute1,
                      setTuesdayCloseHour1,
                      setTuesdayCloseMinute1
                    );
                  }}
                  allowClear={false}
                  defaultValue={defaultTimeout(
                    TuesdayOpenHour1,
                    TuesdayOpenMinute1,
                    TuesdayCloseHour1,
                    TuesdayCloseMinute1
                  )}
                  style={{ width: 300 }}
                />
              </div>
              <div style={{ marginTop: 10 }}>
                <RangePicker
                  picker="time"
                  format="HH:mm"
                  onChange={(value) => {
                    onChangeHour2(
                      value,
                      setTuesdayOpenHour2,
                      setTuesdayOpenMinute2,
                      setTuesdayCloseHour2,
                      setTuesdayCloseMinute2
                    );
                  }}
                  style={{ width: 300 }}
                  defaultValue={
                    TuesdayOpenHour2
                      ? defaultTimeout(
                          TuesdayOpenHour2,
                          TuesdayOpenMinute2,
                          TuesdayCloseHour2,
                          TuesdayCloseMinute2
                        )
                      : null
                  }
                />
              </div>
            </section>
          </div>
        </div>

        {/* End */}

        {/* Miécoles */}

        <div className="day">
          <div style={{ marginTop: 10 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3 style={{ fontWeight: "bold" }}>
                Miércoles{" "}
                {WednesdayOpen ? (
                  <Tag color="green">
                    <span>Abierto</span>
                  </Tag>
                ) : (
                  <Tag color="red">
                    <span>Cerrado</span>
                  </Tag>
                )}
              </h3>
              <Switch
                checked={WednesdayOpen}
                onChange={onChangeWednesday}
                checkedChildren="Abierto"
                unCheckedChildren="Cerrado"
              />
            </div>

            <section>
              <div style={{ marginTop: 10 }}>
                <RangePicker
                  picker="time"
                  format="HH:mm"
                  onChange={(value) => {
                    onChangeHour(
                      value,
                      setWednesdayOpenHour1,
                      setWednesdayOpenMinute1,
                      setWednesdayCloseHour1,
                      setWednesdayCloseMinute1
                    );
                  }}
                  allowClear={false}
                  defaultValue={defaultTimeout(
                    WednesdayOpenHour1,
                    WednesdayOpenMinute1,
                    WednesdayCloseHour1,
                    WednesdayCloseMinute1
                  )}
                  style={{ width: 300 }}
                />
              </div>
              <div style={{ marginTop: 10 }}>
                <RangePicker
                  picker="time"
                  format="HH:mm"
                  onChange={(value) => {
                    onChangeHour2(
                      value,
                      setWednesdayOpenHour2,
                      setWednesdayOpenMinute2,
                      setWednesdayCloseHour2,
                      setWednesdayCloseMinute2
                    );
                  }}
                  style={{ width: 300 }}
                  defaultValue={
                    WednesdayOpenHour2
                      ? defaultTimeout(
                          WednesdayOpenHour2,
                          WednesdayOpenMinute2,
                          WednesdayCloseHour2,
                          WednesdayCloseMinute2
                        )
                      : null
                  }
                />
              </div>
            </section>
          </div>
        </div>

        {/* End */}

        {/* Jueves */}

        <div className="day">
          <div style={{ marginTop: 10 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3 style={{ fontWeight: "bold" }}>
                Jueves{" "}
                {ThursdayOpen ? (
                  <Tag color="green">
                    <span>Abierto</span>
                  </Tag>
                ) : (
                  <Tag color="red">
                    <span>Cerrado</span>
                  </Tag>
                )}
              </h3>
              <Switch
                checked={ThursdayOpen}
                onChange={onChangeThursday}
                checkedChildren="Abierto"
                unCheckedChildren="Cerrado"
              />
            </div>

            <section>
              <div style={{ marginTop: 10 }}>
                <RangePicker
                  picker="time"
                  format="HH:mm"
                  onChange={(value) => {
                    onChangeHour(
                      value,
                      setThursdayOpenHour1,
                      setThursdayOpenMinute1,
                      setThursdayCloseHour1,
                      setThursdayCloseMinute1
                    );
                  }}
                  allowClear={false}
                  defaultValue={defaultTimeout(
                    ThursdayOpenHour1,
                    ThursdayOpenMinute1,
                    ThursdayCloseHour1,
                    ThursdayCloseMinute1
                  )}
                  style={{ width: 300 }}
                />
              </div>
              <div style={{ marginTop: 10 }}>
                <RangePicker
                  picker="time"
                  format="HH:mm"
                  onChange={(value) => {
                    onChangeHour2(
                      value,
                      setThursdayOpenHour2,
                      setThursdayOpenMinute2,
                      setThursdayCloseHour2,
                      setThursdayCloseMinute2
                    );
                  }}
                  style={{ width: 300 }}
                  defaultValue={
                    ThursdayOpenHour2
                      ? defaultTimeout(
                          ThursdayOpenHour2,
                          ThursdayOpenMinute2,
                          ThursdayCloseHour2,
                          ThursdayCloseMinute2
                        )
                      : null
                  }
                />
              </div>
            </section>
          </div>
        </div>

        {/* End */}

        {/* Viernes */}

        <div className="day">
          <div style={{ marginTop: 10 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3 style={{ fontWeight: "bold" }}>
                Viernes{" "}
                {FridayOpen ? (
                  <Tag color="green">
                    <span>Abierto</span>
                  </Tag>
                ) : (
                  <Tag color="red">
                    <span>Cerrado</span>
                  </Tag>
                )}
              </h3>
              <Switch
                checked={FridayOpen}
                onChange={onChangeFriday}
                checkedChildren="Abierto"
                unCheckedChildren="Cerrado"
              />
            </div>

            <section>
              <div style={{ marginTop: 10 }}>
                <RangePicker
                  picker="time"
                  format="HH:mm"
                  onChange={(value) => {
                    onChangeHour(
                      value,
                      setFridayOpenHour1,
                      setFridayOpenMinute1,
                      setFridayCloseHour1,
                      setFridayCloseMinute1
                    );
                  }}
                  allowClear={false}
                  defaultValue={defaultTimeout(
                    FridayOpenHour1,
                    FridayOpenMinute1,
                    FridayCloseHour1,
                    FridayCloseMinute1
                  )}
                  style={{ width: 300 }}
                />
              </div>
              <div style={{ marginTop: 10 }}>
                <RangePicker
                  picker="time"
                  format="HH:mm"
                  onChange={(value) => {
                    onChangeHour2(
                      value,
                      setFridayOpenHour2,
                      setFridayOpenMinute2,
                      setFridayCloseHour2,
                      setFridayCloseMinute2
                    );
                  }}
                  style={{ width: 300 }}
                  defaultValue={
                    FridayOpenHour2
                      ? defaultTimeout(
                          FridayOpenHour2,
                          FridayOpenMinute2,
                          FridayCloseHour2,
                          FridayCloseMinute2
                        )
                      : null
                  }
                />
              </div>
            </section>
          </div>
        </div>

        {/* End */}

        {/* Sábado */}

        <div className="day">
          <div style={{ marginTop: 10 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3 style={{ fontWeight: "bold" }}>
                Sábado{" "}
                {SaturdayOpen ? (
                  <Tag color="green">
                    <span>Abierto</span>
                  </Tag>
                ) : (
                  <Tag color="red">
                    <span>Cerrado</span>
                  </Tag>
                )}
              </h3>
              <Switch
                checked={SaturdayOpen}
                onChange={onChangeSaturday}
                checkedChildren="Abierto"
                unCheckedChildren="Cerrado"
              />
            </div>

            <section>
              <div style={{ marginTop: 10 }}>
                <RangePicker
                  picker="time"
                  format="HH:mm"
                  onChange={(value) => {
                    onChangeHour(
                      value,
                      setSaturdayOpenHour1,
                      setSaturdayOpenMinute1,
                      setSaturdayCloseHour1,
                      setSaturdayCloseMinute1
                    );
                  }}
                  allowClear={false}
                  defaultValue={defaultTimeout(
                    SaturdayOpenHour1,
                    SaturdayOpenMinute1,
                    SaturdayCloseHour1,
                    SaturdayCloseMinute1
                  )}
                  style={{ width: 300 }}
                />
              </div>
              <div style={{ marginTop: 10 }}>
                <RangePicker
                  picker="time"
                  format="HH:mm"
                  onChange={(value) => {
                    onChangeHour2(
                      value,
                      setSaturdayOpenHour2,
                      setSaturdayOpenMinute2,
                      setSaturdayCloseHour2,
                      setSaturdayCloseMinute2
                    );
                  }}
                  style={{ width: 300 }}
                  defaultValue={
                    SaturdayOpenHour2
                      ? defaultTimeout(
                          SaturdayOpenHour2,
                          SaturdayOpenMinute2,
                          SaturdayCloseHour2,
                          SaturdayCloseMinute2
                        )
                      : null
                  }
                />
              </div>
            </section>
          </div>
        </div>

        {/* End */}

        {/* Domingo */}

        <div className="day">
          <div style={{ marginTop: 10 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3 style={{ fontWeight: "bold" }}>
                Domingo{" "}
                {SundayOpen ? (
                  <Tag color="green">
                    <span>Abierto</span>
                  </Tag>
                ) : (
                  <Tag color="red">
                    <span>Cerrado</span>
                  </Tag>
                )}
              </h3>
              <Switch
                checked={SundayOpen}
                onChange={onChangeSunday}
                checkedChildren="Abierto"
                unCheckedChildren="Cerrado"
              />
            </div>

            <section>
              <div style={{ marginTop: 10 }}>
                <RangePicker
                  picker="time"
                  format="HH:mm"
                  onChange={(value) => {
                    onChangeHour(
                      value,
                      setSundayOpenHour1,
                      setSundayOpenMinute1,
                      setSundayCloseHour1,
                      setSundayCloseMinute1
                    );
                  }}
                  allowClear={false}
                  defaultValue={defaultTimeout(
                    SundayOpenHour1,
                    SundayOpenMinute1,
                    SundayCloseHour1,
                    SundayCloseMinute1
                  )}
                  style={{ width: 300 }}
                />
              </div>
              <div style={{ marginTop: 10 }}>
                <RangePicker
                  picker="time"
                  format="HH:mm"
                  onChange={(value) => {
                    onChangeHour2(
                      value,
                      setSundayOpenHour2,
                      setSundayOpenMinute2,
                      setSundayCloseHour2,
                      setSundayCloseMinute2
                    );
                  }}
                  style={{ width: 300 }}
                  defaultValue={
                    SundayOpenHour2
                      ? defaultTimeout(
                          SundayOpenHour2,
                          SundayOpenMinute2,
                          SundayCloseHour2,
                          SundayCloseMinute2
                        )
                      : null
                  }
                />
              </div>
            </section>
          </div>
        </div>

        {/* End */}
      </div>

      <Button
        onClick={handleOk}
        style={{ width: "100%", height: 50, borderRadius: 100, marginTop: 30 }}
        type="primary"
      >
        Actualizar Horario
      </Button>
    </div>
  );
}
