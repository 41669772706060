import { Button, Modal, Tag } from "antd";
import React, { useState } from "react";
import EditHour from "./Edit";

export default function HorarioReserva({
  visible,
  handleCancel,
  user,
  refetch,
}: any) {
  const [visibleEdit, setvisibleEdit] = useState(false);
  const [title, setTiele] = useState("");
  const [hour, setHours] = useState<any>(null);

  const onOpenModal = (tit: string, hours: any) => {
    setvisibleEdit(true);
    setTiele(tit);
    setHours(hours);
  };

  return (
    <Modal
      title="Editar horario de reservas"
      visible={visible}
      onCancel={handleCancel}
      footer={null}
    >
      <div>
        <h2>Horario</h2>

        <div style={{ marginBottom: 15 }}>
          <h3>Lunes</h3>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {user.reservationScheduled["Lunes"].length === 0 ? (
              <Tag color="red">Cerrado</Tag>
            ) : (
              <div>
                {user.reservationScheduled["Lunes"].map(
                  (item: any, i: number) => {
                    return (
                      <p key={i} style={{ color: "gray", margin: 0 }}>
                        {item.star}:00 a {item.end}:00
                      </p>
                    );
                  }
                )}
              </div>
            )}
            <div>
              <Button
                type="primary"
                onClick={() =>
                  onOpenModal("Lunes", user.reservationScheduled["Lunes"])
                }
              >
                Editar Lunes
              </Button>
            </div>
          </div>
        </div>

        <div style={{ marginBottom: 15 }}>
          <h3>Martes</h3>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {user.reservationScheduled["Martes"].length === 0 ? (
              <Tag color="red">Cerrado</Tag>
            ) : (
              <div>
                {user.reservationScheduled["Martes"].map(
                  (item: any, i: number) => {
                    return (
                      <p key={i} style={{ color: "gray", margin: 0 }}>
                        {item.star}:00 a {item.end}:00
                      </p>
                    );
                  }
                )}
              </div>
            )}
            <div>
              <Button
                type="primary"
                onClick={() =>
                  onOpenModal("Martes", user.reservationScheduled["Martes"])
                }
              >
                Editar Martes
              </Button>
            </div>
          </div>
        </div>

        <div style={{ marginBottom: 15 }}>
          <h3>Miércoles</h3>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {user.reservationScheduled["Miércoles"].length === 0 ? (
              <Tag color="red">Cerrado</Tag>
            ) : (
              <div>
                {user.reservationScheduled["Miércoles"].map(
                  (item: any, i: number) => {
                    return (
                      <p key={i} style={{ color: "gray", margin: 0 }}>
                        {item.star}:00 a {item.end}:00
                      </p>
                    );
                  }
                )}
              </div>
            )}
            <div>
              <Button
                type="primary"
                onClick={() =>
                  onOpenModal(
                    "Miércoles",
                    user.reservationScheduled["Miércoles"]
                  )
                }
              >
                Editar Miércoles
              </Button>
            </div>
          </div>
        </div>

        <div style={{ marginBottom: 15 }}>
          <h3>Jueves</h3>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {user.reservationScheduled["Jueves"].length === 0 ? (
              <Tag color="red">Cerrado</Tag>
            ) : (
              <div>
                {user.reservationScheduled["Jueves"].map(
                  (item: any, i: number) => {
                    return (
                      <p key={i} style={{ color: "gray", margin: 0 }}>
                        {item.star}:00 a {item.end}:00
                      </p>
                    );
                  }
                )}
              </div>
            )}
            <div>
              <Button
                type="primary"
                onClick={() =>
                  onOpenModal("Jueves", user.reservationScheduled["Jueves"])
                }
              >
                Editar Jueves
              </Button>
            </div>
          </div>
        </div>

        <div style={{ marginBottom: 15 }}>
          <h3>Viernes</h3>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {user.reservationScheduled["Viernes"].length === 0 ? (
              <Tag color="red">Cerrado</Tag>
            ) : (
              <div>
                {user.reservationScheduled["Viernes"].map(
                  (item: any, i: number) => {
                    return (
                      <p key={i} style={{ color: "gray", margin: 0 }}>
                        {item.star}:00 a {item.end}:00
                      </p>
                    );
                  }
                )}
              </div>
            )}
            <div>
              <Button
                type="primary"
                onClick={() =>
                  onOpenModal("Viernes", user.reservationScheduled["Viernes"])
                }
              >
                Editar Viernes
              </Button>
            </div>
          </div>
        </div>

        <div style={{ marginBottom: 15 }}>
          <h3>Sábado</h3>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {user.reservationScheduled["Sábado"].length === 0 ? (
              <Tag color="red">Cerrado</Tag>
            ) : (
              <div>
                {user.reservationScheduled["Sábado"].map(
                  (item: any, i: number) => {
                    return (
                      <p key={i} style={{ color: "gray", margin: 0 }}>
                        {item.star}:00 a {item.end}:00
                      </p>
                    );
                  }
                )}
              </div>
            )}
            <div>
              <Button
                type="primary"
                onClick={() =>
                  onOpenModal("Sábado", user.reservationScheduled["Sábado"])
                }
              >
                Editar Sábado
              </Button>
            </div>
          </div>
        </div>

        <div style={{ marginBottom: 15 }}>
          <h3>Domingo</h3>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {user.reservationScheduled["Domingo"].length === 0 ? (
              <Tag color="red">Cerrado</Tag>
            ) : (
              <div>
                {user.reservationScheduled["Domingo"].map(
                  (item: any, i: number) => {
                    return (
                      <p key={i} style={{ color: "gray", margin: 0 }}>
                        {item.star}:00 a {item.end}:00
                      </p>
                    );
                  }
                )}
              </div>
            )}
            <div>
              <Button
                type="primary"
                onClick={() =>
                  onOpenModal("Domingo", user.reservationScheduled["Domingo"])
                }
              >
                Editar Domingo
              </Button>
            </div>
          </div>
        </div>
      </div>
      {hour ? (
        <EditHour
          visibleEdit={visibleEdit}
          title={title}
          setvisibleEdit={setvisibleEdit}
          hours={hour}
          setHours={setHours}
          user={user}
          refetch={refetch}
        />
      ) : null}
    </Modal>
  );
}
