import React from "react";
import "./sesion.css";
import Item1 from "../../../Assets/images/item.svg";
import Item2 from "../../../Assets/images/item1.svg";
import Item3 from "../../../Assets/images/item2.svg";
import { Link } from "react-router-dom";

export default function Sesion() {
  return (
    <div className="sesion4">
      <h1>¿Cómo funciona Wilbby®?</h1>
      <div className="sesion4_cont">
        <div>
          <h3>Une tu tienda</h3>
          <img src={Item1} alt="Wilbby" />
          <p>
            Envianos tu solicitud y un especialista te contactará para ayudarte
            con todo el proceso de digitalización de tu negocio.
          </p>
        </div>
        <div>
          <h3>Prepára tu negocio</h3>
          <img src={Item2} alt="Wilbby" />
          <p>
            Sube tus productos, si necesitas programa una sesión de fotos y te
            preparamos tus productos por ti.
          </p>
        </div>
        <div>
          <h3>Empieza a vender</h3>
          <img src={Item3} alt="Wilbby" />
          <p>
            Llega a nuevos clientes con Wilbby, facilita el proceso de pedido y
            añade canales de venta a tu tienda.
          </p>
        </div>
      </div>
      <div className="liogon">
        <div>
          <h1>Ya eres parte de Wilbby para partners</h1>
          <p>
            Ve a tu panel de control donde podrás gestionar tus pedidos,
            productos y tienda
          </p>
        </div>
        <Link to="/login">
          {localStorage.getItem("id") ? "Ir al Dashboard" : "Iniciar sesión"}
        </Link>
      </div>

      <div className="more">
        <h1>Empieza a ofrecer pedidos a domicilio con Wilbby</h1>
        <p>
          Digitaliza tu tienda con nuestra app y acepta pedidos online para
          enviar a domicilio en tu ciudad
        </p>
      </div>
    </div>
  );
}
