import React, { useState } from "react";
import "./index.css";
import Logo from "../../Assets/images/logo.svg";
import { Link } from "react-router-dom";
import Barra from "../../Components/BarraLeft";
import { DownOutlined } from "@ant-design/icons";
import Header from "../../Components/Header";
import { Query } from "react-apollo";
import { query } from "../../GraphQL";
import { Spin, Result } from "antd";

//pages
import Memu from "../../Components/Menu";
import Order from "../../Components/Orders/order";
import Products from "../../Components/AddProduct/AllProductosContent";
import Tienda from "../../Components/Store/edit";
import Rating from "../../Components/Rating";
import Reservas from "../../Components/Reservas";

export default function Dashboard() {
  const [active, setactive] = useState(1);
  return (
    <Query
      query={query.RESTAURANT}
      variables={{ id: localStorage.getItem("id") }}
    >
      {(response) => {
        if (response.loading) {
          return (
            <div className="loading_cont">
              <Spin size="large" />
            </div>
          );
        }
        if (response) {
          const user =
            response && response.data && response.data.getRestaurantForID
              ? response.data.getRestaurantForID.data
              : {};
          response.refetch();

          return (
            <>
              <div className="cont_dashboar">
                <div className="cont_dashboar_menu">
                  <div className="logo_container">
                    <Link to="/dashboard">
                      <img src={Logo} alt="Wilbby" />
                    </Link>
                  </div>
                  <div className="menu_container">
                    <Barra
                      user={user}
                      active={active}
                      onClickOders={() => setactive(1)}
                      onClickStore={() => setactive(2)}
                      onClickMenu={() => setactive(3)}
                      onClickInHouse={() => setactive(7)}
                      onClickTransaction={() => setactive(4)}
                      onClickStart={() => setactive(5)}
                      onClickStatitis={() => setactive(6)}
                      onClickProduct={() => setactive(8)}
                    />
                  </div>
                  <div className="city_content">
                    <p>{user.city}</p>
                    <DownOutlined style={{ color: "#90c33c" }} />
                  </div>
                </div>
                <div className="cont_dashboar_content">
                  <Header user={user} refetch={response.refetch} />
                  <div className="content_full">
                    {active === 1 ? <Order user={user} /> : null}
                    {active === 2 ? (
                      <Tienda data={user} refetch={response.refetch} />
                    ) : null}
                    {active ===4 ? <Reservas user={user} refetch={response.refetch} /> : null}
                    {active === 3 ? <Memu user={user._id} inHouse={false} store={user} /> : null}
                    {active === 7 ? <Memu user={user._id} inHouse={true} store={user} /> : null}
                    {active === 5 ? <Rating user={user} /> : null}
                    {active === 8 ? <Products user={user._id} /> : null}
                  </div>
                </div>
              </div>
            </>
          );
        }
      }}
    </Query>
  );
}
