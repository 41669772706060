import React from "react";
import "./index.css";

export default function Items(props: any) {
  const { data, title, nota } = props;

  return (
    <div className="items-cart">
      <h3>{title}</h3>

      <div className="items-cart-horario">
        <p style={{ color: "gray" }}>
          {nota ? nota : "No tiene nota del pedido"}
        </p>
        <p style={{ color: "gray" }}>
          {data.Needcutlery ? "Incluir cubiertos" : "No incluir cubiertos"}
        </p>
      </div>
    </div>
  );
}
