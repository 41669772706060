import React from "react";
import "./index.css";
import { formaterPrice } from "../../../Utils/FormaterPrice";
import { Button } from "antd";

export default function Items(props: any) {
  const { data, title } = props;

  var totals = 0;

  data.items.forEach(function (items: any) {
    const t = items.items.quantity * items.items.price;
    totals += t;
    items.items.subItems.forEach((x: any) => {
      const s = x.quantity * x.price;
      totals += s * items.items.quantity;
      const t = x && x.subItems ? x.subItems : [];
      t.forEach((y: any) => {
        const u = y.quantity * y.price;
        totals += u * items.items.quantity;
      });
    });
  });

  return (
    <div className="items-cart">
      <h3>{title}</h3>

      <div className="items-cart-resumen">
        <div>
          <ul>
            <li>
              <p>Productos:</p>
            </li>
            <li>
              <p>Descuento:</p>
            </li>
            <li>
              <h1>Total:</h1>
            </li>
          </ul>
        </div>

        <div style={{ marginLeft: "auto" }}>
          <ul>
            <li>
              <p style={{ textAlign: "right" }}>
                {formaterPrice(totals / 100, "es-ES", "EUR")}
              </p>
            </li>
            <li>
              <p style={{ textAlign: "right" }}>
                {" "}
                - {formaterPrice(data.discountTotal / 100, "es-ES", "EUR")}
              </p>
            </li>
            <li>
              <h1>{formaterPrice(totals / 100, "es-ES", "EUR")}</h1>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
