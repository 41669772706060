import React from "react";
import "./index.css";
import {
  ShoppingOutlined,
  ShopOutlined,
  AppstoreOutlined,
  CreditCardOutlined,
  BarChartOutlined,
  StarOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
  ExclamationCircleOutlined,
  TagOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import { Modal } from "antd";

const { confirm } = Modal;

function Barra(props: any) {
  const {
    onClickOders,
    onClickStore,
    onClickMenu,
    onClickInHouse,
    onClickTransaction,
    onClickStart,
    onClickProduct,
    active,
    user,
    history,
  } = props;

  const LogOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    history.push("/login");
  };

  function showDeleteConfirm() {
    confirm({
      title: "¿Estás seguro que deseas cerrar sesión?",
      icon: <ExclamationCircleOutlined />,
      content: "Esperamos verte pronto por aquí",
      okText: "Cerrar sesión",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        LogOut();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  return (
    <div className="barra">
      <ul>
        <li className={active === 1 ? "menu_active" : "menu_inactive"}>
          <ShoppingOutlined />
          <button onClick={onClickOders}>Pedidos</button>
        </li>
        {user.aceptReservation ? (
          <li className={active === 4 ? "menu_active" : "menu_inactive"}>
            <CalendarOutlined />
            <button onClick={onClickTransaction}>Reservas</button>
          </li>
        ) : null}

        <li className={active === 2 ? "menu_active" : "menu_inactive"}>
          <ShopOutlined />
          <button onClick={onClickStore}>Tienda</button>
        </li>
        <li className={active === 8 ? "menu_active" : "menu_inactive"}>
          <TagOutlined />
          <button onClick={onClickProduct}>Productos</button>
        </li>
        {user.inHouse ? (
          <li className={active === 7 ? "menu_active" : "menu_inactive"}>
            <AppstoreOutlined />
            <button onClick={onClickInHouse}>Menú local</button>
          </li>
        ) : null}

        <li className={active === 3 ? "menu_active" : "menu_inactive"}>
          <AppstoreOutlined />
          <button onClick={onClickMenu}>Menú delivery</button>
        </li>

        <li className={active === 5 ? "menu_active" : "menu_inactive"}>
          <StarOutlined />
          <button onClick={onClickStart}>Valoraciones</button>
        </li>
        {/*  <li className={active === 6 ? "menu_active" : "menu_inactive"}>
          <BarChartOutlined />
          <button onClick={onClickStatitis}>Estadísticas</button>
        </li> */}
        {/* <li className={active === 7 ? "menu_active" : "menu_inactive"}>
          <QuestionCircleOutlined />
          <button onClick={onClickHelp}>Ayuda</button>
        </li> */}
        <li className="menu_inactive LogOut">
          <LogoutOutlined />
          <button onClick={showDeleteConfirm}>Cerrar sesión</button>
        </li>
      </ul>
    </div>
  );
}

export default withRouter(Barra);
