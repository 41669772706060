import React, { useState } from "react";
import "./index.css";
import { message, Modal, Input, InputNumber, Button } from "antd";
import { mutations, query } from "../../../GraphQL";
import { useMutation, useQuery } from "react-apollo";
import AddModifield from "../AddModifield";
import { formaterPrice } from "../../../Utils/FormaterPrice";
export default function AddBundled(props) {
  const { refetch, visible, setVisible } = props;
  const [title, settitle] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [max, setmax] = useState(1);
  const [min, setmin] = useState(1);
  const [mulmax, setmulmax] = useState(1);
  const [mulmin, setmulmin] = useState(1);
  const [products, setproducts] = useState([]);

  const store = localStorage.getItem("id");

  const [createModifieldGroup] = useMutation(mutations.ADD_MODIFIED_GROUP);

  const response = useQuery(query.GET_MODIFIELDS, {
    variables: { store: localStorage.getItem("id") },
  });

  const bundleds =
    response && response.data && response.data.getNewProductoModifield
      ? response.data.getNewProductoModifield.data
      : [];

  const deletedItem = (ids) => {
    var i = products.findIndex((x) => x === ids);
    if (i !== -1) {
      products.splice(i, 1);
      setproducts(products.concat());
    }
  };

  const SelectProduct = (ids) => {
    setproducts(products.concat(ids));
  };

  const input = {
    name: title,
    description: "",
    account: store,
    location: store,
    productType: 1,
    plu: "",
    price: 0,
    sortOrder: 0,
    deliveryTax: 0,
    takeawayTax: 0,
    multiply: mulmin,
    multiMax: mulmax,
    posProductId: "",
    posProductCategoryId: [],
    subProducts: products,
    productTags: [],
    posCategoryIds: [],
    imageUrl: null,
    max: max,
    min: min,
    capacityUsages: [],
    parentId: store,
    visible: true,
    snoozed: false,
    subProductSortOrder: [],
    recomended: false,
    quantity: 1,
    new: false,
    popular: false,
    offert: false,
    storeId: store,
  };

  const handleOk = () => {
    setConfirmLoading(true);
    createModifieldGroup({ variables: { input: { data: input } } })
      .then((res) => {
        if (res.data.createModifieldGroup.success) {
          message.success("Producto añadido con éxito");
          refetch();
          response.refetch();
          setVisible(false);
          setConfirmLoading(false);
        } else {
          message.warning("Algo no va bien intentalo de nuevo");
          setConfirmLoading(false);
          refetch();
          response.refetch();
        }
      })
      .catch(() => {
        refetch();
        message.error("Algo salio mal intentalo de nuevo");
        setConfirmLoading(false);
      });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  function onChangeMax(value) {
    setmax(value);
  }

  function onChangeMin(value) {
    console.log(value);
    setmin(value);
  }

  function onChangemulMax(value) {
    setmulmax(value);
  }

  function onChangemulMin(value) {
    setmulmin(value);
  }

  const isOk = () => {
    if (title) {
      return false;
    } else {
      return true;
    }
  };

  const onchangeTitle = (e) => {
    e.preventDefault();
    settitle(e.target.value);
  };

  return (
    <Modal
      title="Crear modificado opcional"
      visible={visible}
      onOk={handleOk}
      okText="Crear opción"
      cancelText="Cancelar"
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      okButtonProps={{ disabled: isOk() }}
    >
      <div className="content_modal">
        <div className="add_info">
          <p>
            Nombre del producto <span style={{ color: "red" }}>*</span>
          </p>
          <Input
            placeholder="Nombre del producto"
            onChange={onchangeTitle}
            defaultValue={title}
          />
          <br />

          <div className="btn_contents">
            <InputNumber
              min={1}
              max={100}
              style={{ width: "49%" }}
              placeholder="Minímo"
              value={min}
              type="number"
              onChange={onChangeMin}
            />
            <InputNumber
              min={1}
              max={100}
              style={{ width: "51%" }}
              placeholder="Maximo"
              value={max}
              type="number"
              onChange={onChangeMax}
            />
          </div>

          <div className="btn_contents">
            <InputNumber
              min={1}
              max={100}
              style={{ width: "49%" }}
              placeholder="Minímo multiplicable"
              value={mulmin}
              type="number"
              onChange={onChangemulMin}
            />
            <InputNumber
              min={1}
              max={100}
              style={{ width: "51%" }}
              placeholder="Maximo multiplicable"
              value={mulmax}
              type="number"
              onChange={onChangemulMax}
            />
          </div>
        </div>

        <div style={{ marginTop: 20 }}>
          <h3>Opciones</h3>
        </div>

        <AddModifield
          store={localStorage.getItem("id")}
          refetch={response.refetch}
          bundled={true}
        />

        <div>
          {bundleds &&
            bundleds.map((item, i) => {
              const adds =
                products && products.filter((x) => x === item._id).length > 0;

              return (
                <Button
                  onClick={() =>
                    adds ? deletedItem(item._id) : SelectProduct(item._id)
                  }
                  type={adds ? "primary" : "dashed"}
                  key={i}
                  style={{ marginRight: 10, marginTop: 10 }}
                >
                  {item.name} (× {item.multiMax}){" "}
                  {item.price > 0
                    ? `  +${formaterPrice(item.price / 100, "", "")}`
                    : null}
                </Button>
              );
            })}
        </div>
      </div>
    </Modal>
  );
}
