import React, { useState } from "react";
import "./index.css";
import { Tag, Menu, Dropdown, message, Button, Switch, Tooltip } from "antd";
import { TagOutlined, PercentageOutlined } from "@ant-design/icons";
import { formaterPrice } from "../../../Utils/FormaterPrice";
import EditProduct from "../../AddProduct/EditProducto";
import { useMutation } from "react-apollo";
import { mutations } from "../../../GraphQL";

export default function Card(props: any) {
  const { data, refetch, titleCat, dataCategory, getData, inHouse } = props;
  const [dataDetails, setdataDetails] = useState(null);
  const [visible, setVisible] = useState(false);
  const [actualizarCategoria] = useMutation(mutations.UPDATE_CATEGORY);
  const [snoozedProduct] = useMutation(mutations.SNOOZED_PRODUCT);

  const deletedItem = (ids: string) => {
    var i = dataCategory.products.findIndex((x: any) => x === ids);
    if (i !== -1) {
      dataCategory.products.splice(i, 1);

      actualizarCategoria({ variables: { input: { data: dataCategory } } })
        .then((res) => {
          if (res.data.actualizarCategoria.success) {
            message.success("Producto eliminado de la categoria");
            getData();
          } else {
            message.success("Algo salio mal intentalo de nuevo");
            getData();
          }
        })
        .catch(() => {
          message.success("Algo salio mal intentalo de nuevo");
        });
    }
  };

  const setDetalles = (prod: any) => {
    setdataDetails(prod);
    setVisible(true);
    refetch();
  };

  const snoozedProducts = (snoozed: boolean) => {
    snoozedProduct({ variables: { id: data._id, snoozed: snoozed } })
      .then((res) => {
        if (res.data.snoozedProduct.success) {
          message.success("Producto marcado como agotado o deshabilitado");
          refetch();
        } else {
          message.success("Algo salio mal intentalo de nuevo");
          refetch();
        }
      })
      .catch(() => {
        message.success("Algo salio mal intentalo de nuevo");
      });
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setDetalles(data)}>Editar producto</Menu.Item>
      <Menu.Item danger onClick={() => deletedItem(data._id)}>
        Eliminar prodcuto
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="card_product">
        <div className="product_info">
          <div className="line-container">
            <h3 className="single-line">{data.name}</h3>
            <p className="single-line">{data.description}</p>
            <div className="alergenos_cont">
              {inHouse ? (
                <>
                  {data.alergenos.map((item: any, i: number) => {
                    return (
                      <Tooltip
                        title={item.name}
                        key={i}
                        style={{ margin: "0px 10px" }}
                      >
                        <img src={item.image} alt={item.name} />
                      </Tooltip>
                    );
                  })}
                </>
              ) : null}
            </div>
          </div>
          <div className="tags_product">
            {data.snoozed ? <Tag color="error">Agotado</Tag> : null}
            {data.new ? <Tag color="success">Nueva</Tag> : null}
            {data.offert ? (
              <Tag icon={<PercentageOutlined />} color="orange">
                Oferta
              </Tag>
            ) : null}
            {data.popular ? (
              <Tag icon={<TagOutlined />} color="purple">
                Popular
              </Tag>
            ) : null}
          </div>

          {inHouse ? (
            <div>
              <div className="prices">
                <h2>
                  {formaterPrice(data.priceInHouse / 100, "es-ES", "EUR")}
                </h2>
                {data.previous_priceInHouse ? (
                  <h4>
                    {formaterPrice(
                      data.previous_priceInHouse / 100,
                      "es-ES",
                      "EUR"
                    )}
                  </h4>
                ) : null}

                <span style={{ marginLeft: 5 }}>{data.label}</span>
              </div>

              {data.priceInHouse1 > 0 ? (
                <div className="prices" style={{ marginTop: -15 }}>
                  <h2>
                    {formaterPrice(data.priceInHouse1 / 100, "es-ES", "EUR")}
                  </h2>
                  {data.previous_priceInHouse ? (
                    <h4>
                      {formaterPrice(
                        data.previous_priceInHouse1 / 100,
                        "es-ES",
                        "EUR"
                      )}
                    </h4>
                  ) : null}

                  <span style={{ marginLeft: 5 }}>{data.label1}</span>
                </div>
              ) : null}
            </div>
          ) : (
            <div className="prices">
              <h2>{formaterPrice(data.price / 100, "es-ES", "EUR")}</h2>
              {data.previous_price ? (
                <h4>
                  {formaterPrice(data.previous_price / 100, "es-ES", "EUR")}
                </h4>
              ) : null}
            </div>
          )}

          <div className="toggle_cont">
            <div className="toggle">
              <Switch
                checkedChildren="Agotado"
                unCheckedChildren="En stock"
                defaultChecked={data.snoozed}
                onChange={() => snoozedProducts(data.snoozed ? false : true)}
              />
            </div>
            <div>
              <Dropdown overlay={menu}>
                <Button
                  type="primary"
                  onClick={(e) => e.preventDefault()}
                  style={{ height: 35, borderRadius: 100 }}
                >
                  Opciones
                </Button>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="product_img">
          {data.imageUrl ? <img src={data.imageUrl} alt={data.title} /> : null}
        </div>
      </div>
      {dataDetails ? (
        <EditProduct
          datas={dataDetails}
          refetch={refetch}
          visible={visible}
          setVisible={setVisible}
          titleCat={titleCat}
          setdataDetails={setdataDetails}
        />
      ) : null}
    </>
  );
}
