import React, { useState } from "react";
import "./index.css";
import {
  TwitterOutlined,
  InstagramFilled,
  FacebookFilled,
  LinkedinFilled,
  TransactionOutlined,
  MailOutlined,
  RightOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
  ShopOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";
import { Select, Modal } from "antd";
import SimpleMap from "./Maps";
import { Link } from "react-router-dom";

import MainLogo from "../../Assets/images/icon.png";
import Android from "../../Assets/images/google.png";
import Apple from "../../Assets/images/apple.svg";

const { Option } = Select;

const Footer = () => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <div className="Footer">
        <div className="Footer-Content">
          <div>
            <img
              src={MainLogo}
              style={{ width: 60, marginBottom: 15, borderRadius: 15 }}
            />
            <div>
              <p className="text">Aumenta tus vetas hasta un 50% con Wilbby</p>
            </div>
            <div>
                <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.foodyapp"
              >
                <img
                  src={Android}
                  className="logos googl"
                  style={{ marginRight: 15 }}
                /> 
              </a>
              <a
                target="_blank"
                href="https://apps.apple.com/es/app/wilbby-comida-a-domicilio/id1553798083"
              >
                <img src={Apple} className="logos googl" />
              </a>
            </div>
            <div style={{ marginTop: 20 }}>
              <a
                href="https://twitter.com/wilbbyapp"
                className="icons"
                target="_blank"
              >
                <TwitterOutlined style={{ fontSize: 22, marginRight: 15 }} />
              </a>
              <a
                href="https://www.facebook.com/wilbbyapp"
                className="icons"
                target="_blank"
              >
                <FacebookFilled style={{ fontSize: 22, marginRight: 15 }} />
              </a>
              <a
                href="https://www.instagram.com/wilbbyapp"
                className="icons"
                target="_blank"
              >
                <InstagramFilled style={{ fontSize: 22, marginRight: 15 }} />
              </a>
              <a
                href="https://www.linkedin.com/company/wilbbyapp/"
                className="icons"
                target="_blank"
              >
                <LinkedinFilled style={{ fontSize: 22, marginRight: 15 }} />
              </a>
            </div>
          </div>
          <div className="SuBitemsxs">
            <ul>
              <li>
                <span className="SuBitemsspan">Acerca de Wilbby®</span>
              </li>
              <li>
                <a href="#unirme">Unirme ahora</a>
              </li>
              <li>
                <a href="https://wilbby.com/riders">Reparte con Wilbby</a>
              </li>
              <li>
                <a href="https://wilbby.com/team">Nuestro equipo</a>
              </li>
              <li>
                <a href="https://blog.wilbby.com/" target="_blank">
                  Blog
                </a>
              </li>
              <li>
                <a href="/riders">Trabaja con nosotros</a>
              </li>
            </ul>
          </div>
          <div className="SuBitemsxs">
            <ul>
              <li>
                <span className="SuBitemsspan">Obtener ayuda</span>
              </li>
              <li>
                <a
                  onClick={() => setVisible(true)}
                  style={{ cursor: "pointer" }}
                >
                  Contacto
                </a>
              </li>
              <li>
                <a href="https://wilbby.com/preguntas-frecuentes">
                  Preguntas frecuentes
                </a>
              </li>
              <li>
                <a href="https://wilbby.com/politica-de-privacidad">
                  Privacidad
                </a>
              </li>
              <li>
                <a href="https://wilbby.com/condiciones-de-uso">
                  Condiciones de uso
                </a>
              </li>
              <li>
                <a href="https://wilbby.com/cookies">Cookies</a>
              </li>
            </ul>
          </div>
          <div className="SuBitems">
            <Select defaultValue="españa" style={{ width: 140 }}>
              <Option value="españa"> 🇪🇸 España</Option>
            </Select>
            <br />
            <Select
              defaultValue="español"
              style={{ width: 140, marginTop: 20, color: "#212121" }}
              bordered={false}
            >
              <Option value="idioma">
                {" "}
                <TransactionOutlined
                  style={{ color: "#90C33C", marginRight: 5 }}
                />{" "}
                Idioma
              </Option>
              <Option value="español"> 🇪🇸 Español</Option>
            </Select>
          </div>
        </div>
        <div className="Subfooter" />
        <div className="firma">
          <p style={{ color: "gray", marginRight: 20 }}>
            {" "}
            © 2021 Hecho con 😍 en Burgos Wilbby®
          </p>
        </div>
      </div>
      <Modal
        title="Contacto"
        visible={visible}
        footer={false}
        onCancel={() => setVisible(false)}
      >
        <SimpleMap
          lat={42.3509557}
          lgn={-3.7657629}
          width="100%"
          height={200}
          title="Wilbby®"
        />
        <div style={{ margin: 20, paddingBottom: 30 }}>
          <div className="item_contact">
            <MailOutlined
              style={{ marginRight: 15, color: "#90C33C", fontSize: 22 }}
            />{" "}
            <a
              href="mailto://info@Wilbby®app.es"
              style={{ color: "black" }}
              target="_blank"
            >
              info@Wilbby.com
            </a>
            <RightOutlined style={{ marginLeft: "auto" }} />
          </div>
          <div className="item_contact">
            <PhoneOutlined
              style={{ marginRight: 15, color: "#90C33C", fontSize: 22 }}
            />{" "}
            <a href="tel:+34640823527" style={{ color: "black" }}>
              Hablar con el equipo de Wilbby®
            </a>
            <RightOutlined style={{ marginLeft: "auto" }} />
          </div>
          <div className="item_contact">
            <EnvironmentOutlined
              style={{ marginRight: 15, color: "#90C33C", fontSize: 22 }}
            />{" "}
            <Link to="/" style={{ color: "black" }}>
              Burgos ES
            </Link>
            <RightOutlined style={{ marginLeft: "auto" }} />
          </div>
          <div className="item_contact">
            <ShopOutlined
              style={{ marginRight: 15, color: "#90C33C", fontSize: 22 }}
            />{" "}
            <a
              href="https://stores.wilbby.com/"
              style={{ color: "black" }}
              target="_black"
            >
              Une tu restaurante
            </a>
            <RightOutlined style={{ marginLeft: "auto" }} />
          </div>
          <div className="item_contact">
            <ShoppingOutlined
              style={{ marginRight: 15, color: "#90C33C", fontSize: 22 }}
            />{" "}
            <Link to="/riders" style={{ color: "black" }} target="_black">
              Reparte con nosotros
            </Link>
            <RightOutlined style={{ marginLeft: "auto" }} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Footer;
