import React, { useState, useEffect } from "react";
import { Button, Form, Input, Select, Checkbox } from "antd";
import "./form.css";
import animationData from "../../../Assets/Animate/success.json";
import Lottie from "react-lottie";
import { LOCAL_API_URL } from "../../../config/";
import dataMunicipio from "./municipio.json";
import { message } from "antd";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const { Option } = Select;

export default function Forms() {
  const [done, setDone] = useState(false);
  const [Loading, setLoading] = useState(false);

  const onChange = async (values) => {
    setLoading(true);
    const data = { values };
    fetch(`${LOCAL_API_URL}/contact-for-restaurant`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (res) => {
        const data = await res.json();
        if (data.success) {
          message.success(data.messages);
          setDone(true);
        } else {
          setLoading(false);
          message.warning("Alno va bien intentalo de nuevo");
        }
      })
      .catch((e) => {
        setLoading(false);
        setDone(false);
        message.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="form_content" id="unirme">
      {done ? (
        <div style={{ textAlign: "center" }}>
          <Lottie options={defaultOptions} height={200} width={200} />
          <h1 style={{ marginBottom: 30 }}>Solicitud enviada con éxito</h1>
          <p style={{ color: "gray" }}>
            Solicitud enviada con éxito en un plazo de 24 a 48H nos pondremos en
            contacto contigo para continuar la solicitud.
          </p>
          <Button
            type="primary"
            shape="round"
            onClick={() => setDone(false)}
            style={{ width: 200, height: 50 }}
            loading={false}
          >
            Volver al inicio
          </Button>
        </div>
      ) : (
        <>
          <h1>¡Únete hoy mismo!</h1>
          <Form onFinish={(value) => onChange(value)}>
            <Form.Item name="title">
              <Input placeholder="Nombre de tu tienda" required={true} />
            </Form.Item>

            <Form.Item name="adress">
              <Input placeholder="Dirección" type="text" required={true} />
            </Form.Item>

            <div style={{ display: "flex", marginBottom: 20 }}>
              <Form.Item style={{ margin: 5, width: "33%" }} name="type">
                <Select
                  allowClear
                  placeholder="Tipo de establecimiento"
                  required={true}
                >
                  <Option value="Restaurante">Restaurante</Option>
                  <Option value="Supermercado">Supermercado</Option>
                  <Option value="Farmacia">Farmacia</Option>
                  <Option value="Tienda">Tienda</Option>
                  <Option value="Tienda">Desayuno y Meriendas</Option>
                </Select>
              </Form.Item>

              <Form.Item style={{ margin: 5, width: "33%" }} name="country">
                <Select allowClear placeholder="País" required={true}>
                  <Option value="España">España</Option>
                </Select>
              </Form.Item>

              <Form.Item style={{ margin: 5, width: "33%" }} name="city">
                <Select
                  allowClear
                  placeholder="Ciudad"
                  autoComplete="none"
                  required={true}
                  showSearch
                >
                  {dataMunicipio.map((data, i) => {
                    return (
                      <Option value={data.nombre} key={i}>
                        {data.nombre}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>

            <h2>Contacto</h2>

            <div style={{ display: "flex" }}>
              <Form.Item style={{ margin: 5, width: "50%" }} name="name">
                <Input placeholder="Nombre" required={true} />
              </Form.Item>

              <Form.Item style={{ margin: 5, width: "50%" }} name="lastName">
                <Input placeholder="Apellido(s)" required={true} />
              </Form.Item>
            </div>

            <div style={{ display: "flex", marginBottom: 20 }}>
              <Form.Item style={{ margin: 5, width: "50%" }} name="email">
                <Input placeholder="Correo electrónico" required={true} />
              </Form.Item>

              <Form.Item style={{ margin: 5, width: "50%" }} name="phone">
                <Input
                  placeholder="Telefono"
                  type="tel"
                  required={true}
                  defaultValue="+34"
                />
              </Form.Item>
            </div>
            <Form.Item>
              <Checkbox required={true}>
                Si haces clic en «Enviar», confirmas haber leído y aceptado la{" "}
                <a
                  href="https://wilbby.com/politica-de-privacidad"
                  target="_black"
                >
                  Política de protección de datos de Wilbby
                </a>
                .{" "}
              </Checkbox>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                shape="round"
                htmlType="submit"
                style={{ width: 200, height: 50 }}
                loading={Loading}
              >
                Enviar
              </Button>
            </Form.Item>
          </Form>
        </>
      )}
    </div>
  );
}
