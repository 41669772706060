import React from "react";
import "./sesion.css";
export default function Sesion() {
  return (
    <div className="brand">
      <h1>!Ya colaboran con nosotros!</h1>
      <p>
        Miles de restaurantes, y tiendas de todo el mundo ya confían en nosotros
      </p>
      <div className="brand_cont">
        <img src="https://header-store-wilbby.s3.eu-west-3.amazonaws.com/1653504709235-header-store-wilbby" alt="La Doña" />
        <img src="https://header-store-wilbby.s3.eu-west-3.amazonaws.com/1655316887714-header-store-wilbby" alt="Chesys" />
        <img src="https://header-store-wilbby.s3.eu-west-3.amazonaws.com/1652424802472-header-store-wilbby" alt="Marquinetti" />
        <img src="https://header-store-wilbby.s3.eu-west-3.amazonaws.com/1643729256851-header-store-wilbby" alt="La Cava Cervecería & Vinoteca" />
        <img src="https://header-store-wilbby.s3.eu-west-3.amazonaws.com/logo+1929.png" alt="Cervecería 1929" />
      </div>
    </div>
  );
}
