import React, { useState } from "react";
import "./index.css";
import { PlusCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Modal, Input, message, Tooltip, Upload } from "antd";
import { useMutation, Mutation } from "react-apollo";
import { mutations } from "../../../GraphQL";
import Tags from "../tag";

const { TextArea } = Input;


function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default function AddCategory(props: any) {
  const { user, getData, menu, inHouse } = props;
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [title, settitle] = useState("");
  const [description, setdescription] = useState("");
  const [image, setimagen] = useState("");
  const [tags, setTags] = useState<any[]>([]);
  const [loadingImage, setloadingImage] = useState(false);


  const [createNewCategory] = useMutation(mutations.CREATE_CATEGORY);


  const showModal = () => {
    setVisible(true);
  };

  const input = {
    name: title,
    description: description,
    account: user,
    subCategories: [],
    imageUrl: image,
    products: [],
    menu: menu ? menu._id : "",
    sortedChannelProductIds: [],
    subProductSortOrder: [],
    subProducts: [],
    level: 1,
    availabilities: [],
    internalId: user,
    storeId: user,
    sorting: -2,
    inHouse: inHouse,
    tagsOffert: tags.length > 0 ? tags.map((t)=> {
      return {
        name: t,
        color: "#ff7d00"
      }
    }) : []
  };

  const isOk = () => {
    if(title && image) {
      return false
    } else {
      return true
    }
  }

  const handleOk = () => {
    setConfirmLoading(true);
    createNewCategory({ variables: { input: { data: input } } })
      .then((res) => {
        if (res.data.createNewCategory.success) {
          message.success(res.data.createNewCategory.messages);
          setConfirmLoading(false);
          getData();
          setVisible(false);
        } else {
          message.warning(res.data.createNewCategory.messages);
          setConfirmLoading(false);
          getData();
        }
      })
      .catch((e) => {
        console.log(e);
        message.error("Algo salio mal intentalo de nuevo");
        setConfirmLoading(false);
      });
  };

  const handleCancel = () => {
    setVisible(false);
  };


  const uploadButton = (
    <Tooltip title="Añadir foto">
      <div style={{height: 230, display: "grid", alignItems: "center"}}>
        <div className="ant-upload-text">
        {loadingImage ? (
          <LoadingOutlined style={{ fontSize: 30, color: "#90c33c" }} />
        ) : (
          <PlusCircleOutlined style={{ fontSize: 30, color: "#90c33c" }} />
        )}
          <p>Añadir foto <span style={{color: "red"}}>(Obligatorio)</span></p>
        </div>
      </div>
    </Tooltip>
  );

  return (
    <>
      <div className="Add_menu" onClick={showModal}>
        <PlusCircleOutlined style={{ fontSize: 30, color: "#90c33c" }} />
        <p>Añadir categoría</p>
      </div>
      <Modal
        title="Añadir categoría a tu menú"
        visible={visible}
        onOk={handleOk}
        okText="Anadir Categoría"
        cancelText="Cancelar"
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okButtonProps={{disabled: isOk()}}
      >
        <div className="contents_modal">
        <Mutation mutation={mutations.UPLOAD_FILE_AWS}>
            {(singleUploadToAws: any) => (
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={async (data) => {
                  setloadingImage(true);
                  let imgBlob = await getBase64(data.file);
                  singleUploadToAws({ variables: { imgBlob } })
                    .then((res: any) => {
                      setloadingImage(false);
                      setimagen(res.data.singleUploadToAws.data.Location);
                    })
                    .catch((error: any) => {
                      setloadingImage(false);
                      message.warning(
                        "Imagen demaciado grande intenta con una más pequeña"
                      );
                    });
                }}
              >
                {image ? (
                  <Tooltip title="Haz click para cambiar">
                    <img
                      className="imagen_prod_add_category_upd"
                      src={image}
                    />
                  </Tooltip>
                ) : null}

                {!image ? uploadButton : null}
              </Upload>
            )}
          </Mutation>
          <Input
            placeholder="Añadir un nombre (Obligatorio)"
            onChange={(e) => settitle(e.target.value)}
            style={{marginTop: 15}}
          />
          <br />
          <br />
          <TextArea
            placeholder="Añadir descripción"
            onChange={(e) => setdescription(e.target.value)}
            showCount
            maxLength={60}
          />
        </div>

        <div style={{ marginBottom: 20, marginTop: 20, marginLeft: 20, marginRight: 20 }}>
          <Tags tags={tags} setTags={setTags} />
        </div>
      </Modal>
    </>
  );
}
