import React from "react";
import "./index.css";
import moment from "moment";
import { Button } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";

export default function Items(props: any) {
  const { data, title } = props;

  return (
    <div className="items-cart">
      <div className="items-cart-horario">
        <h3>Hora de entrada</h3>
        <p>{moment(data.created_at).utcOffset(120).format("lll")}</p>
      </div>

      {data.scheduled ? (
        <div className="items-cart-horario">
          <h3>Pedido programado</h3>
          <Button
            type="primary"
            icon={<ClockCircleOutlined />}
            danger
            style={{ marginBottom: 10 }}
          >
            {moment(data.deliveryTime).utcOffset(120).format("lll")}
          </Button>
        </div>
      ) : (
        <div className="items-cart-horario">
          <h3>{title}</h3>
          <p>
            {moment(data.created_at)
              .utcOffset(120)
              .add(45, "minute")
              .format("lll")}
          </p>
        </div>
      )}

      <div className="items-cart-horario">
        <h3>Tipo de pedido</h3>
        <p>
          {data.orderType === "delivery"
            ? "Entrega a domicilio"
            : "Para recoger por el cliente"}
        </p>
      </div>
    </div>
  );
}
