import React, { useEffect } from "react";
import "./index.css";
import { Input, Switch, Badge, message } from "antd";
import { AudioOutlined, BellOutlined } from "@ant-design/icons";
import { mutations, query } from "../../GraphQL";
import { useQuery, useMutation } from "react-apollo";

const { Search } = Input;

export default function Header(props: any) {
  const { user, refetch } = props;
  const [actualizarRestaurant] = useMutation(mutations.ACTUALIZAR_STORE);

  const suffix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: "#90C33C",
      }}
    />
  );

  const onSearch = (value: string) => console.log(value);

  const handleChange = (checked: boolean) => {
    const input = {
      _id: user._id,
      open: checked,
    };
    actualizarRestaurant({ variables: { input: input } }).then((res) => {
      if (res.data.actualizarRestaurant.success) {
        refetch();
        message.success("Tienda actualizada con éxito");
      } else {
        message.warning("Algo salio mal intentalo de nuevo");
      }
    });
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://cdn.headwayapp.co/widget.js";
    document.head.appendChild(script);
    var config = {
      selector: ".headway-badge",
      account: "76mADx",
    };
    script.onload = function () {
      //@ts-ignore
      window.Headway.init(config);
    };
  }, []);

  return (
    <div className="header_cont">
      <div className="header_items">
        <div>
          <Switch
            checkedChildren="Abierto"
            unCheckedChildren="Cerrado"
            defaultChecked={user.open}
            onChange={handleChange}
          />
        </div>
        <Search
          placeholder="Buscar en Wilbby My Store"
          enterButton="Buscar"
          size="large"
          suffix={suffix}
          onSearch={onSearch}
          style={{ width: 500, borderRadius: 20 }}
        />
        <div>
          <button className="notification_btn">
            <BellOutlined style={{ fontSize: 20, color: "white" }} />
            <span
              className="headway-badge"
              id="headway-badge"
              style={{ marginLeft: -17, marginTop: -8, position: "absolute" }}
            />
          </button>
        </div>
      </div>
    </div>
  );
}
