import React from "react";
import "./card.css";
import { formaterPrice } from "../../Utils/FormaterPrice";
import { TagOutlined, PercentageOutlined } from "@ant-design/icons";
import { Tag, Tooltip } from "antd";

export default function CardProducts(props: any) {
  const { data, onclick, product, setproducts, inHouse } = props;
  const adds =
    product && product.filter((x: string) => x === data._id).length === 1;

  const deletedItem = (ids: string) => {
    var i = product.findIndex((x: any) => x === ids);
    if (i !== -1) {
      product.splice(i, 1);
      setproducts(product.concat());
    }
  };

  return (
    <div
      className={adds ? "card_Products_add" : "card_Products"}
      onClick={() => (adds ? deletedItem(data._id) : onclick(data))}
    >
      <div>
        <h3>{data.name}</h3>
        <p>{data.description}</p>
        {inHouse ? (
          <div style={{margin: "10px 0"}}>
            {data.alergenos.map((item: any, i: number) => {
              return (
                <Tooltip
                  title={item.name}
                  key={i}
                  style={{ margin: "0px 7px" }}
                >
                  <img src={item.image} alt={item.name} className="image_alerge" />
                </Tooltip>
              );
            })}
          </div>
        ) : null}
        {inHouse ? (
          <div className="prices">
            <h2>{formaterPrice(data.priceInHouse / 100, "es-ES", "EUR")}</h2>
            {data.previous_priceInHouse ? (
              <h4>
                {formaterPrice(
                  data.previous_priceInHouse / 100,
                  "es-ES",
                  "EUR"
                )}
              </h4>
            ) : null}
          </div>
        ) : (
          <div className="prices">
            <h2>{formaterPrice(data.price / 100, "es-ES", "EUR")}</h2>
            {data.previous_price ? (
              <h4>
                {formaterPrice(data.previous_price / 100, "es-ES", "EUR")}
              </h4>
            ) : null}
          </div>
        )}
        {data.snoozed ? <Tag color="error">Agotado</Tag> : null}
        {data.new ? <Tag color="success">Nueva</Tag> : null}
        {data.offert ? (
          <Tag icon={<PercentageOutlined />} color="orange">
            Oferta
          </Tag>
        ) : null}
        {data.popular ? (
          <Tag icon={<TagOutlined />} color="purple">
            Popular
          </Tag>
        ) : null}
      </div>
      <div>
        {data.imageUrl ? (
          <img src={data.imageUrl} alt={data.name} />
        ) : (
          <img
            src="https://is3-ssl.mzstatic.com/image/thumb/Purple114/v4/8c/78/9c/8c789cdd-a5b5-4803-89ce-551f5ddb7afc/source/512x512bb.jpg"
            alt={data.name}
          />
        )}
      </div>
    </div>
  );
}
