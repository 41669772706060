import React from "react";
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  RollbackOutlined,
  CheckOutlined,
  SyncOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import "./card.css";
import { Modal, Table, Tag, Button, message } from "antd";
import Details from "../DetailsCard";
import { useMutation } from "react-apollo";
import { mutations } from "../../../GraphQL";
import moment from "moment";
import "moment/locale/es";
import { Countdown } from "../time";

const { confirm } = Modal;

export default function Card(props: any) {
  const { loading, datos, setpage, setlimit, onChangePage, refetch, limit, documents } =
    props;

  const [NewOrdenProceed] = useMutation(mutations.NEW_ORDER_PROCEED);

  const returOrder = (orders: any) => {
    refetch();
    NewOrdenProceed({
      variables: {
        ordenId: orders._id,
        status: "Rechazada por la tienda",
        IntegerValue: 0,
        statusProcess: {
          status: "Rechazada por la tienda",
          date: moment(new Date()).utcOffset(120).format(),
        },
      },
    }).then((res: any) => {
      refetch();
      message.success("El pedido ha sido marcado como devuelto");
    });
  };

  const proccessOrder = (orders: any, status: string, value: number) => {
    refetch();
    NewOrdenProceed({
      variables: {
        ordenId: orders._id,
        status: status,
        IntegerValue: value,
        statusProcess: {
          status: status,
          date: moment(new Date()).utcOffset(120).format(),
        },
      },
    })
      .then((res: any) => {
        refetch();
        message.success("Pedido actualizado");
      })
      .catch(() => {
        message.error("Algo salio mal intenlo de nuevo");
      });
  };

  const renderBTNStatus = (order: any) => {
    switch (order.status) {
      case "Nueva":
        return (
          <Button
            type="primary"
            onClick={() => proccessOrder(order, "Confirmada", 40)}
          >
            Confirmar pedido
          </Button>
        );

      case "Confirmada":
        return (
          <Button
            type="primary"
            onClick={() => proccessOrder(order, "En la cocina", 50)}
          >
            Pasar a la cocina
          </Button>
        );

      case "En la cocina":
        return (
          <Button
            type="primary"
            onClick={() => proccessOrder(order, "Listo para recoger", 60)}
          >
            Listo para recoger
          </Button>
        );

      case "Devuelto":
        return (
          <Button
            danger
            type="primary"
            onClick={() => null}
            disabled={order.status === "Devuelto"}
          >
            Devuelto
          </Button>
        );

      default:
        return (
          <Button type="primary" onClick={() => null}>
            Pedido completado
          </Button>
        );
    }
  };

  function showDeleteConfirm(data: any) {
    confirm({
      title: "¿Estás seguro que deseas hacer la devolución?",
      icon: <ExclamationCircleOutlined />,
      content: "Lamentamos no poder terminar este pedido",
      okText: "Devolver",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        returOrder(data);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  const columns = [
    {
      title: "Orden ID",
      dataIndex: "",
      key: "",
      render: (d: any) => {
        return (
          <div>
            <Tag color="#f50">#{d.channelOrderDisplayId}</Tag>
            <br />
            <p style={{ marginTop: 5 }}>
              <ClockCircleOutlined />{" "}
              {moment(d.created_at).utcOffset(120).format("HH:mm")}
            </p>
          </div>
        );
      },
    },
    {
      title: "Cliente",
      dataIndex: "",
      key: "x",
      render: (d: any) => {
        return (
          <div>
            <p style={{ margin: 0, padding: 0 }}>
              {d.customerData.name} {d.customerData.lastName}
            </p>
            {d.orderIsAlreadyPaid ? null : (
              <Tag color="#87d068">Pago en Efectivo</Tag>
            )}

            {!d.orderIsAlreadyPaid ? <br /> : null}

            {d.orderType === "pickup" ? null : (
              <span style={{ color: "gray" }}>
                {d.deliveryAddressData.formatted_address},{" "}
                {d.deliveryAddressData.puertaPiso},{" "}
                {` ${d.deliveryAddressData.postalcode}`
                  ? d.deliveryAddressData.postalcode
                  : " No postalcode"}
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: "Rider",
      dataIndex: "",
      key: "x",
      render: (d: any) => {
        return (
          <div>
            {!d.storeData.autoshipping ? (
              <div>
                {d.orderType === "delivery" ? (
                  <div>
                    {d.courier ? (
                      <Button
                        type="dashed"
                        disabled={d.status === "Devuelto" ? true : false}
                      >
                        {d.courierData.name} {d.courierData.lastName}
                      </Button>
                    ) : (
                      <Button
                        type="dashed"
                        disabled={d.status === "Devuelto" ? true : false}
                      >
                        Sin asignar
                      </Button>
                    )}
                  </div>
                ) : (
                  <Button type="dashed" danger>
                    Para recoger
                  </Button>
                )}
              </div>
            ) : (
              <Button type="dashed" danger>
                Entregado por la tienda
              </Button>
            )}
          </div>
        );
      },
    },
    {
      title: "Estado",
      dataIndex: "",
      key: "x",
      render: (d: any) => {
        const renderStatus = () => {
          switch (d.status) {
            case "Rechazada por el rider":
              return (
                <Tag color="warning" icon={<ExclamationCircleOutlined />}>
                  {d.status}
                </Tag>
              );
            case "Rechazada por la tienda":
              return (
                <Tag icon={<CloseCircleOutlined />} color="error">
                  {d.status}
                </Tag>
              );
            case "Resolución":
              return (
                <Tag icon={<CloseCircleOutlined />} color="error">
                  {d.status}
                </Tag>
              );
            case "Devuelto":
              return (
                <Tag icon={<RollbackOutlined />} color="error">
                  {d.status}
                </Tag>
              );
            case "Nueva":
              return (
                <Tag icon={<CheckOutlined />} color="warning">
                  {d.status}
                </Tag>
              );

            case "Entregada":
              return (
                <Tag icon={<CheckCircleOutlined />} color="success">
                  {d.status}
                </Tag>
              );

            case "Finalizada":
              return (
                <Tag icon={<CheckCircleOutlined />} color="success">
                  {d.status}
                </Tag>
              );
            default:
              return (
                <Tag icon={<SyncOutlined spin />} color="processing">
                  {d.status}
                </Tag>
              );
          }
        };
        return (
          <div>
            {renderStatus()}{" "}
            <Countdown
              eventTime={Number(
                moment(d.scheduled ? d.deliveryTime : d.created_at)
                  .utcOffset(120)
                  .add(45, "minutes")
                  .format("X")
              )}
              interval={1000}
              scheduled={d.scheduled}
              status={d.status}
            />
          </div>
        );
      },
    },

    {
      title: "Acción",
      dataIndex: "",
      key: "x",
      render: (data: any) => {
        return renderBTNStatus(data);
      },
    },

    {
      title: "Devolver",
      dataIndex: "",
      key: "x",
      render: (data: any) => {
        return (
          <Button
            danger
            type="primary"
            onClick={() => showDeleteConfirm(data)}
            disabled={data.status === "Devuelto"}
          >
            {data.status === "Devuelto" ? "Devuelto" : "Rechazar pedido"}
          </Button>
        );
      },
    },
  ];

  return (
    <Table
      scroll={{ x: "calc(100vh - 4em)" }}
      rowKey={(record) => record.channelOrderDisplayId}
      onChange={(pagination) => {
        setpage(pagination.current);
        setlimit(pagination.pageSize);
      }}
      columns={columns}
      loading={loading}
      expandable={{
        expandedRowRender: (record) => {
          return (
            <Details
              data={record}
              refetch={refetch}
              NewOrdenProceed={NewOrdenProceed}
            />
          );
        },
        rowExpandable: (record) =>
          record.channelOrderDisplayId === record.channelOrderDisplayId,
      }}
      dataSource={datos}
      pagination={{
        defaultCurrent: 1,
        onChange: onChangePage,
        total: documents,
        pageSize: limit,
      }}
    />
  );
}

/* 

reporte:

id, 
fecha
repartidor
total productos

 */
