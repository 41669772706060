import styles from "./style.module.css";
import { List } from "antd";
import { formaterPrice } from "../../../Utils/FormaterPrice";

export default function Product(props: any) {
  const { data, loading, language, currency } = props;

  const renderItems = (item: any, i: any) => {
    return (
      <div className="info_product" key={i}>
        <div style={{ marginTop: 5 }}>
          <p>
            <span style={{ color: "#90c33c" }}>
              {item.subItems && item.subItems.length > 0
                ? item.quantity
                : item.quantity > 1
                ? `${item.quantity} x`
                : "-"}{" "}
            </span>{" "}
            {item.name}{" "}
            <span style={{ color: "#90c33c" }}>
              {formaterPrice(item.price / 100, language, currency)}
            </span>
          </p>
          {item.subItems &&
            item.subItems.map((s: any, i: number) => {
              return (
                <p key={i}>
                  <span style={{ color: "#90c33c" }}>{s.quantity} x</span>{" "}
                  {s.name}{" "}
                  <span style={{ color: "#90c33c" }}>
                    {formaterPrice(s.price / 100, language, currency)}
                  </span>
                </p>
              );
            })}
        </div>
      </div>
    );
  };

  const recorre = data.length > 0 ? data : [];

  return (
    <div className="addres_info_container">
      {recorre.map((x: any, i: any) => {
        return renderItems(x, i);
      })}
    </div>
  );
}
