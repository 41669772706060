import React from "react";
import "./index.css";
import Items from "./Items";
import Products from "./Products";
import Resumen from "./Resumen";
import PaymentType from "./PaymentType";
import Hora from "./Hora";
import Nota from "./Nota";
import Status from "./Status";
import Distance from "./Distance";
import Cupon from "./Cupon";

export default function DetailsCard(props: any) {
  const { data } = props;

  return (
    <div className="new-menu-cart-container">
      <div className="new-cart-wrapper">
        <div className="new-cart-content">
          <div className="carts">
            <Items
              title="Info del cliente"
              title2={`${data.customerData.name} ${data.customerData.lastName}`}
              sub={data.customerData.telefono}
              sub1={data.customerData.email}
            />

            {data.courierData && data.courier ? (
              <Items
                title="Info del repartidor"
                title2={`${data.courierData.name} ${data.courierData.lastName}`}
                sub={data.courierData.telefono}
                sub1={data.courierData.email}
              />
            ) : null}
            {data.orderType === "delivery" ? (
              <Items
                title="Dirección de entrega"
                p={`${data.deliveryAddressData.formatted_address},
                ${data.deliveryAddressData.puertaPiso},
                ${
                  data.deliveryAddressData.postalcode
                    ? data.deliveryAddressData.postalcode
                    : "No postalcode"
                },
                ${data.deliveryAddressData.city}, ${
                  data.deliveryAddressData.type
                }`}
              />
            ) : null}
            {/* <Status title="Estado del pedido" data={data} /> */}
          </div>
          <div
            className="carts"
            style={{ width: "55%", marginRight: 40, minWidth: 500 }}
          >
            <Products title="Productos" data={data} />
          </div>
          <div className="carts">
            <Hora title="Hora de entrega" data={data} />
            <Nota title="Nota del pedido" nota={data.note} data={data} />
            {data.cupon ? <Cupon title="Cupon" data={data} /> : null}
            <Resumen title="Resumen" data={data} />
            <PaymentType title="Método de pago" data={data} />
          </div>
        </div>
      </div>
    </div>
  );
}
