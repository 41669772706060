import React, { useState } from "react";
import Card from "./Card";
import "./index.css";
import { DatePicker, Button, Input, Select } from "antd";
import moment from "moment";
import { useQuery } from "react-apollo";
import { query } from "../../GraphQL";
//@ts-ignore
import locate from "moment/locale/es";
import Report from "./Report";

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

const date = new Date();
let day = date.getDate();
let month = date.getMonth() + 1;
let year = date.getFullYear();

let day1 = date.getDate();
let month1 = date.getMonth() + 2;
let year1 = date.getFullYear();

const dateFormat = "MM/DD/YYYY";
const fromDate = `${month}/${day}/${year}`;
const toDate = `${month1}/${day1}/${year1}`;

export default function Order({ user }) {
  const [isModalReportVisible, setIsModalReportVisible] = useState(false);
  const [page, setpage] = useState(1);
  const [limit, setlimit] = useState(10);
  const [dateRange, setDateRange] = useState(null);
  const [tipo, setTipo] = useState("Periodo");
  const [search, setSearch] = useState(null);

  const {
    data = {},
    loading,
    refetch,
    error
  } = useQuery(query.GET_ORDER, {
    variables: {
      page: page,
      limit: limit,
      storeId: user._id,
      search: search,
      dateRange: {
        fromDate: dateRange ? dateRange.fromDate : null,
        toDate: dateRange ? dateRange.toDate : null,
      },
      status: [
        "Nueva",
        "Confirmada",
        "En la cocina",
        "Listo para recoger",
        "Preparando para el envío",
        "En camino",
        "Entregada",
        "Rechazado",
        "Rechazada por la tienda",
        "Rechazada por el rider",
        "Devuelto",
        "Finalizada",
        "Resolución",
      ],
    },
  });

  const { getNewOrderforStoreWeb } = data;

  const orders = getNewOrderforStoreWeb ? getNewOrderforStoreWeb.list : [];

  const documents = getNewOrderforStoreWeb ? getNewOrderforStoreWeb.total : 0;

  function handleChangeTipo(value) {
    setTipo(value);
  }

  const onDateRangeChange = (dates, dateStrings) => {
    const fromDate = dateStrings[0];
    const toDate = dateStrings[1];
    setDateRange({
      fromDate:
        tipo === "Sólo un día" ? moment(dates).format(dateFormat) : fromDate,
      toDate: tipo === "Sólo un día" ? null : toDate,
    });
  };

  const onSearch = (search) => {
    setSearch(Number(search));
    setpage(1);
  };

  const onChangePage = (page, pageSize) => {
    setpage(page);
    setlimit(pageSize);
  };

  return (
    <div>
      <div>
        <div className="search_container">
          <div>
            <h3>Filtrar por fecha o ID</h3>
            <Select
              allowClear
              value={tipo}
              style={{
                width: "auto",
                height: 35,
                marginRight: 10,
              }}
              placeholder="Elige un tipo"
              onChange={handleChangeTipo}
            >
              <Option value="Periodo">Periodo</Option>
              <Option value="Sólo un día">Sólo un día</Option>
            </Select>
            {tipo === "Periodo" ? (
              <RangePicker
                locale={locate}
                defaultValue={[
                  moment(fromDate, dateFormat),
                  moment(toDate, dateFormat),
                ]}
                format={dateFormat}
                onChange={onDateRangeChange}
                style={{
                  height: 35,
                  marginRight: 10,
                  marginBottom: 15,
                }}
              />
            ) : (
              <DatePicker
                onChange={onDateRangeChange}
                style={{
                  height: 35,
                  marginRight: 10,
                  marginBottom: 15,
                }}
                defaultValue={moment(fromDate, dateFormat)}
                format={dateFormat}
                locale={locate}
              />
            )}

            {/*  <Button
              onClick={() => setIsModalReportVisible(true)}
              style={{
                marginTop: 15,
                height: 40,
                width: 363,
              }}
              type="primary"
            >
              OBTENER REPORTE
            </Button> */}
          </div>
          <div>
            <Search
              style={{ width: "100%", minWidth: 363, marginTop: 15 }}
              placeholder="Busca un pedido por ID"
              allowClear
              type="number"
              enterButton="Buscar"
              size="large"
              onSearch={onSearch}
              maxLength={6}
            />
          </div>
        </div>
        <Card
          loading={loading}
          datos={orders}
          setpage={setpage}
          setlimit={setlimit}
          refetch={refetch}
          onChangePage={onChangePage}
          limit={limit}
          documents={documents}
        />
      </div>
      <Report
        setIsModalVisible={setIsModalReportVisible}
        isModalVisible={isModalReportVisible}
        user={user}
      />
    </div>
  );
}
