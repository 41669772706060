import React from "react";
import { Form, Input, Button, Checkbox, message } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import "./index.css";
import { useMutation } from "react-apollo";
import { mutations } from "../../GraphQL";
import { withRouter } from "react-router-dom";

const NormalLoginForm = (props: any) => {
  const { setView, history } = props;
  const [autenticarRestaurant] = useMutation(mutations.AUTENTICAR_RESTAURANT);

  const onFinish = (values: any) => {
    autenticarRestaurant({
      variables: { email: values.email, password: values.password },
    })
      .then((res) => {
        if (res.data.autenticarRestaurant.success) {
          localStorage.setItem(
            "token",
            res.data.autenticarRestaurant.data.token
          );
          localStorage.setItem("id", res.data.autenticarRestaurant.data.id);
          message.success(res.data.autenticarRestaurant.message);
          history.push("/dashboard");
        } else {
          message.error(res.data.autenticarRestaurant.message);
        }
      })
      .catch(() => {
        message.success("Algo no va bien intentalo de nuevo");
      });
  };

  return (
    <Form
      name="normal_login"
      className="login-form"
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      <Form.Item
        name="email"
        rules={[{ required: true, message: "Please input your email!" }]}
      >
        <Input
          prefix={<MailOutlined className="site-form-item-icon" />}
          placeholder="Correo eléctronico"
          type="email"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: "Please input your Password!" }]}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Contraseña"
        />
      </Form.Item>
      <Form.Item>
        <Form.Item valuePropName="checked" noStyle>
          <Checkbox>Recordar</Checkbox>
        </Form.Item>

        <Button type="link" onClick={() => setView("Forgot")}>
          ¿La olvidaste?
        </Button>
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          shape="round"
          style={{ width: "100%" }}
        >
          Iniciar sesión
        </Button>
      </Form.Item>
    </Form>
  );
};

export default withRouter(NormalLoginForm);
