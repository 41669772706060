import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import PrivateRoute from "../PrivateRouter";
import PublicRouter from "../PublicRouter";
import { Result } from "antd";

//Page
import DashBoar from "../Pages/DashBoadr";
import Landing from "../Pages/Landing";
import Login from "../Pages/Login";
import NoFound from "../Pages/Nofound";
function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Landing} />
        <PublicRouter path="/login" exact component={Login} />
        <PrivateRoute path="/dashboard" exact component={DashBoar} />
        <Route component={NoFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default Router;
