import React, { useState } from "react";
import "./index.css";
import { message, Modal, Input, InputNumber, Button } from "antd";
import { mutations, query } from "../../../GraphQL";
import { useMutation, useQuery } from "react-apollo";
import AddProduct from "../../AddProduct/add";
import { formaterPrice } from "../../../Utils/FormaterPrice";
export default function EdditBundled(props) {
  const { refetch, datas, visible, setVisible, setdataDetails } = props;
  const [title, settitle] = useState(datas.name);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [max, setmax] = useState(datas.max);
  const [min, setmin] = useState(datas.min);
  const [mulmax, setmulmax] = useState(datas.multiMax);
  const [mulmin, setmulmin] = useState(datas.multiply);
  const [products, setproducts] = useState(datas.subProducts);

  const [actualizarBundled] = useMutation(mutations.ACTUALIZAR_BUNDLED);

  const response = useQuery(query.GET_PRODUCT_BUNDLED, {
    variables: { store: localStorage.getItem("id") },
  });

  const bundleds =
    response && response.data && response.data.getNewProductoBundled
      ? response.data.getNewProductoBundled.data
      : [];

  if (response) {
    response && response.refetch();
  }

  const deletedItem = (ids) => {
    var i = products.findIndex((x) => x === ids);
    if (i !== -1) {
      products.splice(i, 1);
      setproducts(products.concat());
    }
  };

  const SelectProduct = (ids) => {
    setproducts(products.concat(ids));
  };

  const input = {
    _id: datas._id,
    name: title,
    description: datas.description,
    account: datas.account,
    location: datas.location,
    productType: datas.productType,
    plu: datas.plu,
    price: datas.price,
    sortOrder: datas.sortOrder,
    deliveryTax: datas.deliveryTax,
    takeawayTax: datas.takeawayTax,
    multiply: mulmin,
    multiMax: mulmax,
    posProductId: datas.posProductId,
    posProductCategoryId: datas.posProductCategoryId,
    subProducts: products,
    productTags: datas.productTags,
    posCategoryIds: datas.posCategoryIds,
    imageUrl: null,
    max: max,
    min: min,
    capacityUsages: datas.capacityUsages,
    parentId: datas.parentId,
    visible: datas.visible,
    snoozed: datas.snoozed,
    subProductSortOrder: [],
    recomended: datas.recomended,
    quantity: datas.quantity,
    new: datas.new,
    popular: datas.popular,
    offert: datas.offert,
    previous_price: datas.previous_price,
    storeId: datas.storeId,
  };

  const handleOk = () => {
    setConfirmLoading(true);
    actualizarBundled({ variables: { input: { data: input } } })
      .then((res) => {
        if (res.data.actualizarBundled.success) {
          refetch();
          setVisible(false);
          setdataDetails(null);
          setConfirmLoading(false);
          message.success("Producto actualizado con éxito");
        } else {
          message.warning("Algo no va bien intentalo de nuevo");
          setConfirmLoading(false);
          refetch();
        }
      })
      .catch(() => {
        refetch();
        message.error("Algo salio mal intentalo de nuevo");
        setConfirmLoading(false);
      });
  };

  const handleCancel = () => {
    setVisible(false);
    setdataDetails(null);
  };

  function onChangeMax(value) {
    setmax(value);
  }

  function onChangeMin(value) {
    console.log(value);
    setmin(value);
  }

  function onChangemulMax(value) {
    setmulmax(value);
  }

  function onChangemulMin(value) {
    setmulmin(value);
  }

  const isOk = () => {
    if (title) {
      return false;
    } else {
      return true;
    }
  };

  const onchangeTitle = (e) => {
    e.preventDefault();
    settitle(e.target.value);
  };

  return (
    <Modal
      title="Editar opciones"
      visible={visible}
      onOk={handleOk}
      okText="Guardar cambios"
      cancelText="Cancelar"
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      okButtonProps={{ disabled: isOk() }}
    >
      <div className="content_modal">
        <div className="add_info">
          <p>
            Nombre del producto <span style={{ color: "red" }}>*</span>
          </p>
          <Input
            placeholder="Nombre del producto"
            onChange={onchangeTitle}
            defaultValue={title}
          />
          <br />

          <div className="btn_contents">
            <InputNumber
              min={1}
              max={100}
              style={{ width: "49%" }}
              placeholder="Minímo"
              value={min}
              type="number"
              onChange={onChangeMin}
            />
            <InputNumber
              min={1}
              max={100}
              style={{ width: "51%" }}
              placeholder="Maximo"
              value={max}
              type="number"
              onChange={onChangeMax}
            />
          </div>

          <div className="btn_contents">
            <InputNumber
              min={1}
              max={100}
              style={{ width: "49%" }}
              placeholder="Minímo multiplicable"
              value={mulmin}
              type="number"
              onChange={onChangemulMin}
            />
            <InputNumber
              min={1}
              max={100}
              style={{ width: "51%" }}
              placeholder="Maximo multiplicable"
              value={mulmax}
              type="number"
              onChange={onChangemulMax}
            />
          </div>
        </div>

        <div style={{ marginTop: 20 }}>
          <h3>Opciones</h3>
        </div>

        <div>
          {bundleds &&
            bundleds.map((item, i) => {
              const adds =
                products && products.filter((x) => x === item._id).length > 0;

              return (
                <Button
                  onClick={() =>
                    adds ? deletedItem(item._id) : SelectProduct(item._id)
                  }
                  type={adds ? "primary" : "dashed"}
                  key={i}
                  style={{ marginRight: 10, marginTop: 10 }}
                >
                  {item.name} (× {item.multiMax}){" "}
                  {item.price > 0
                    ? `  +${formaterPrice(item.price / 100, "", "")}`
                    : null}
                </Button>
              );
            })}
        </div>
        <AddProduct
          store={localStorage.getItem("id")}
          refetch={response.refetch}
          bundled={true}
        />
      </div>
    </Modal>
  );
}
