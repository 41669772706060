import { useQuery } from "react-apollo";
import { query } from "../../GraphQL";
import Categoty from "./category";

export default function Menu(props: any) {
  const { user, inHouse, store } = props;

  

  const response = useQuery(query.NEW_MENU, { variables: { id: user } });

  const menu =
    response && response.data && response.data.getNewMenu
      ? response.data.getNewMenu.list
      : [];

  return (
    <div>
      <Categoty user={user} menu={menu[0]} inHouse={inHouse} store={store} />
    </div>
  );
}
