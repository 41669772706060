import React from "react";
import "./barners.css";
import { Carousel } from "antd";
import Formulario from "../Form/form";
import Imgen from "../../../Assets/images/imagen1.jpg";
import Imgen1 from "../../../Assets/images/imagen2.jpg";
import Imgen2 from "../../../Assets/images/imagen3.jpg";

export default function Barners() {
  return (
    <div className="container_barner">
      <div className="container_barner_item">
        <div className="forms">
          <h1 className="titles" style={{ fontWeight: 900 }}>
            Únete a Wilbby gratis
          </h1>
          <p className="subtitles">
            Aumenta tus ventas hasta un 25% gracias a los pedidos a domicilio
            online.
          </p>
          <Formulario />
        </div>
        <div className="fotos">
          <Carousel effect="fade" dots={false} autoplay>
            <div className="carrousel">
              <img src={Imgen1} alt="Wilbby" />
            </div>
            <div className="carrousel">
              <img src={Imgen} alt="Wilbby" />
            </div>
            <div className="carrousel">
              <img src={Imgen2} alt="Wilbby" />
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
}
