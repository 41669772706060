import React from "react";
import "./sesion.css";
import Imagen1 from "../../../Assets/images/movil.png";
import Imagen2 from "../../../Assets/images/movil1.png";
import Apple from "../../../Assets/images/apple.svg";
import google from "../../../Assets/images/google.png";

export default function Sesion() {
  return (
    <div className="sesion2">
      <h1 className="ti">Descubre la app para partners</h1>
      <div className="sesion2_items">
        <div>
          <img src={Imagen2} alt="Wilbby App" className="movil" />
        </div>

        <div>
          <h1>Gestiona y procesa tu pedidos</h1>
          <p>
            Con la app de My Store my Wilbby es bastante fácil gestionar tus
            pedidos de una forma automática, recibes una notificación cada vez
            que recibes un nuevo pedido y sólo tienes que confirmarlo, una vez
            confirmado se le avisará al rider para que éste pase a recoger el
            pedido.
          </p>
        </div>

        <div>
          <h1>El perfil de tu negocio más intuitivo</h1>
          <p>
            Gestiona tu perfil, modifica tus datos, cambia horario, menú, abre y
            cierra tu tienda cuando quieras desde la app entre otras
            funcionalidades, para que tengas total control de tu negocio en
            nuestra app. Rellena el formulario y en un plazo de 48HR tendrás tu
            tienda en nuestra app. A qué espera para unirte a la revolución del
            servicio a domicilio.
          </p>
          <div className="apps">
            <a
              href="https://apps.apple.com/es/app/my-store-by-wilbby/id1546507971"
              target="_black"
            >
              <img src={Apple} alt="Apple store" target="_black" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.wilbbystoreapp"
              target="_black"
            >
              <img src={google} alt="Play store" />
            </a>
          </div>
        </div>
        <div>
          <img src={Imagen1} alt="Wilbby App" className="movil" />
        </div>
      </div>
    </div>
  );
}
