import React, { useState } from "react";
import {
  message,
  Modal,
  Menu,
  Dropdown,
  Input,
  Switch,
  Tooltip,
  Upload,
} from "antd";
import { ExclamationCircleOutlined, MoreOutlined } from "@ant-design/icons";
import { mutations } from "../../GraphQL";
import { useMutation, Mutation } from "react-apollo";
import { PlusCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import Tags from "./tag";


/*  */

const { confirm } = Modal;

const { TextArea } = Input;

function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default function DeleteMenu(props: any) {
  const { getData, data } = props;
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [title, settitle] = useState(data.name);
  const [description, setdescription] = useState(data.description);
  const [actualizarCategoria] = useMutation(mutations.ACTUALIZAR_CATEGORY);
  const [updateCategoria] = useMutation(mutations.UPDATE_CATEGORY);

  
  const [eliminarCategoria] = useMutation(mutations.DELETE_CATEGORY);
  const [loadingImage, setloadingImage] = useState(false);
  const [image, setimagen] = useState(data.imageUrl);
  const [tags, setTags] = useState<any[]>(data.tagsOffert.map((t: any)=> t.name ? t.name : t));

  const deleteMenu = (ids: string) => {
    eliminarCategoria({ variables: { id: ids } })
      .then((res) => {
        if (res.data.eliminarCategoria.success) {
          setConfirmLoading(false);
          message.success("Categoria eliminada");
          getData();
        } else {
          setConfirmLoading(false);
          message.success("Algo salio mal intentalo de nuevo");
          getData();
        }
      })
      .catch(() => {
        setConfirmLoading(false);
        message.success("Algo salio mal intentalo de nuevo");
      });
  };

  function showDeleteConfirm() {
    confirm({
      title: "¿Estás seguro que deseas eliminar esta categoría?",
      icon: <ExclamationCircleOutlined />,
      content:
        "Al elimiar está categoría se eliminarán todos los productos que esten asociado a ella.",
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteMenu(data._id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  const showModal = () => {
    setVisible(true);
  };

  const input = {
    _id: data._id,
    name: title,
    description: description,
    account: data.account,
    subCategories: [],
    imageUrl: image,
    products: data.products,
    menu: data.menu,
    sortedChannelProductIds: [],
    subProductSortOrder: [],
    subProducts: [],
    level: data.level,
    availabilities: [],
    internalId: data.internalId,
    storeId: data.storeId,
    sorting: data.sorting,
    tagsOffert: tags.map((t)=> {
      return {
        name: t,
        color: "#ff7d00"
      }
    })
  };

  const handleOk = () => {
    setConfirmLoading(true);
    updateCategoria({ variables: { input: { data: input } } })
      .then((res) => {
        if (res.data.updateCategoria.success) {
          setConfirmLoading(false);
          message.success("Categoria actualizada");
          getData();
        } else {
          setConfirmLoading(false);
          message.success("Algo salio mal intentalo de nuevo");
          getData();
        }
      })
      .catch(() => {
        setConfirmLoading(false);
        message.success("Algo salio mal intentalo de nuevo");
      });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const actualizarCategory = (id: any, snoozed: boolean) => {
    actualizarCategoria({
      variables: {
        input: {
          data: {
            _id: id,
            snoozed: snoozed,
          },
        },
      },
    })
      .then((res) => {
        if (res.data.actualizarCategoria.success) {
          getData();
          message.success("Categoria actualizada");
        } else {
          message.success("Algo salio mal");
        }
      })
      .catch((e) => {
        getData();
        console.log(e);
        message.success("Algo salio mal");
      })
      .finally(() => {
        getData();
      });
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={showModal}>Editar categoría</Menu.Item>

      <Menu.Item danger onClick={showDeleteConfirm}>
        Eliminar categoría
      </Menu.Item>
    </Menu>
  );

  const uploadButton = (
    <Tooltip title="Añadir foto">
      <div style={{height: 230, display: "grid", alignItems: "center"}}>
        <div className="ant-upload-text">
        {loadingImage ? (
          <LoadingOutlined style={{ fontSize: 30, color: "#90c33c" }} />
        ) : (
          <PlusCircleOutlined style={{ fontSize: 30, color: "#90c33c" }} />
        )}
          <p>Añadir foto <span style={{color: "red"}}>(Obligatorio)</span></p>
        </div>
      </div>
    </Tooltip>
  );

  return (
    <div className="rigth_cont">
      <Dropdown overlay={menu}>
        <MoreOutlined
          style={{
            marginLeft: "auto",
            fontSize: 22,
            cursor: "pointer",
          }}
          onClick={(e) => e.preventDefault()}
        />
      </Dropdown>
      <Modal
        title="Editar categoría de tu menú"
        visible={visible}
        onOk={handleOk}
        okText="Guardar cambios"
        cancelText="Cancelar"
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <div className="contents_modal">
          <Mutation mutation={mutations.UPLOAD_FILE_AWS}>
            {(singleUploadToAws: any) => (
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={async (data) => {
                  setloadingImage(true);
                  let imgBlob = await getBase64(data.file);
                  singleUploadToAws({ variables: { imgBlob } })
                    .then((res: any) => {
                      setloadingImage(false);
                      setimagen(res.data.singleUploadToAws.data.Location);
                    })
                    .catch((error: any) => {
                      setloadingImage(false);
                      message.warning(
                        "Imagen demaciado grande intenta con una más pequeña"
                      );
                    });
                }}
              >
                {image ? (
                  <Tooltip title="Haz click para cambiar">
                    <img
                      className="imagen_prod_add_category_upd"
                      src={image}
                    />
                  </Tooltip>
                ) : null}

                {!image ? uploadButton : null}
              </Upload>
            )}
          </Mutation>

          <div style={{ marginBottom: 20, marginTop: 20 }}>
            <Switch
              checkedChildren="Ocultada"
              unCheckedChildren="Ocultar categoria"
              defaultChecked={data.snoozed}
              style={{ zIndex: 100 }}
              onChange={() => {
                actualizarCategory(data._id, data.snoozed ? false : true);
              }}
            />
          </div>
          <Input
            placeholder="Añadir un nombre (Obligatorio)"
            defaultValue={title}
            onChange={(e) => settitle(e.target.value)}
          />
          <br />
          <br />
          <TextArea
            rows={4}
            placeholder="Añadir descripción"
            onChange={(e) => setdescription(e.target.value)}
            showCount
            defaultValue={description}
            maxLength={60}
          />
        </div>

        <div style={{ marginBottom: 20, marginTop: 20, marginLeft: 20, marginRight: 20 }}>
          <Tags tags={tags} setTags={setTags} />
        </div>

      </Modal>
    </div>
  );
}
