import React, {useEffect, useState} from 'react';
import { Button, message, Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useMutation, useQuery } from 'react-apollo';
import { mutations, query } from "../../GraphQL";
import moment from 'moment';
import HorarioReserva from './Horario';

export default function Reservas({user}: any) {
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(20);
    const [visible, setvisible] = useState(false);

    const [NewReservaProceed] = useMutation(mutations.UPDATE_RESERVA)

    const {data = {}, loading, refetch} = useQuery(query.GET_RESERVAS, {
        variables: {id: user._id, page: page, limit: limit}
    })

    const {getAllReservaStore} = data

    const datos = getAllReservaStore ? getAllReservaStore.data : []
    const total = getAllReservaStore ? getAllReservaStore.count : 0;


    const handleCancel = () => {
      setvisible(false)
    } 

    const onChangePage = (page: number, pageSize: number) => {
        setPage(page);
        setLimit(pageSize);
      };


    const updateReserva = (id: string, status: string) => {
        NewReservaProceed({variables: {id: id, status: status}}).then((res: any)=> {
            if(res.data.NewReservaProceed.success) {
                refetch();
                message.success("Reserva actualizada con éxito")
            }   else {
                message.warning("Algo salio mal intentalo de nuevo") 
            } 
        }).catch((e: any)=> {
            message.error(JSON.stringify(e)); 
        })
    }


    const renderStatus = (status: string) => {
        switch (status) {
          case "Nueva":
            return <Tag color="purple">{status}</Tag>;
          case "Confirmada":
            return <Tag color="purple">{status}</Tag>;
          case "Finalizada":
            return <Tag color="lime">{status}</Tag>;
          case "Cancelada":
            return <Tag color="red">{status}</Tag>;
            case "Cancelada por el cliente":
              return <Tag color="red">{status}</Tag>;
              case "Cancelada por el establecimiento":
                return <Tag color="red">{status}</Tag>;
          default:
            return <Tag color="purple">{status}</Tag>;
        }
    }

    const renderBtn = (item: any) => {
        switch (item.status) {
          case "Nueva":
            return  <Space size="middle">
            <Button type="primary" onClick={()=> updateReserva(item._id, "Confirmada")}>Confirmar</Button>
            <Button type="primary" danger onClick={()=> updateReserva(item._id, "Cancelada")}>
              Cancelar
            </Button>
          </Space>;
          case "Confirmada":
            return (
              <Space size="middle">
                <Button type="primary" onClick={()=> updateReserva(item._id, "Finalizada")}>Finalizar</Button>
                <Button type="primary" danger onClick={()=> updateReserva(item._id, "Cancelada")}>
                  Cancelar
                </Button>
              </Space>
            );
          case "Finalizada":
            return <Space size="middle">
            <Button type="primary" disabled>Finalizada</Button>
            </Space>;
          case "Cancelada":
            return <Space size="middle">
            <Button type="primary" disabled>Candelada</Button>
            </Space>;
            case "Cancelada por el cliente":
              return <Space size="middle">
              <Button type="primary" disabled>Cancelada por el cliente</Button>
              </Space>;
              case "Cancelada por el establecimiento":
                return <Space size="middle">
                <Button type="primary" disabled>Cancelada por el establecimiento</Button>
                </Space>;
          default:
            return <Space size="middle">
            <Button type="primary" onClick={()=> updateReserva(item._id, "Confirmada")}>Confirmar</Button>
            <Button type="primary" danger onClick={()=> updateReserva(item._id, "Cancelada")}>
              Cancelar
            </Button>
          </Space>;
        }
    }

    const columns: ColumnsType<any> = [
      {
        title: "Fecha",
        key: "date",
        render: (item: any) => {
          return (
            <div>
              <h3 style={{ fontWeight: "bold" }}>
                {moment(item.date).format("ll")}
              </h3>
              <span style={{ color: "#90C33C" }}>Hora: {item.hour}</span>
              <br />
              <span style={{ color: "gray" }}>
                Entrada: {moment(item.created_at).format("ll")}
              </span>
            </div>
          );
        },
      },
      {
        title: "Número de personas",
        dataIndex: "people",
        key: "people",
        render: (item: any) => {
          return <p>{item} personas</p>;
        },
      },
      {
        title: "Cliente",
        dataIndex: "user",
        key: "user",
        render: (item: any) => {
          return (
            <div>
              <h3 style={{ fontWeight: "bold" }}>
                {item.name} {item.lastName}
              </h3>
              <span style={{ color: "gray" }}>{item.email}</span>
              <br />
              <span style={{ color: "#90C33C" }}>Tel: {item.telefono}</span>
            </div>
          );
        },
      },

      {
        title: "Estado",
        key: "status",
        dataIndex: "status",
        render: (status: string) => {
          return renderStatus(status);
        },
      },

      {
        title: "Nota",
        key: "note",
        dataIndex: "note",
        render: (note: any) => {
          return <p>{note ? note : "No tiene nota"}</p>;
        },
      },
      {
        title: "Acciones",
        key: "btn",
        render: (items: any) => renderBtn(items),
      },
    ];
      
    
  return (
    <div>
      <div className="search_container">
        <Button
          type="primary"
          style={{ height: 45 }}
          onClick={() => setvisible(true)}
        >
          Editar Horarrio de reservas
        </Button>
      </div>
      <Table
        scroll={{ x: "calc(100vh - 4em)" }}
        columns={columns}
        dataSource={datos}
        loading={loading}
        pagination={{
          defaultCurrent: 1,
          onChange: onChangePage,
          total: total,
          pageSize: limit,
        }}
      />

      <HorarioReserva
        visible={visible}
        handleCancel={handleCancel}
        user={user}
      />
    </div>
  );
}
