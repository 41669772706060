import React, { useState, useEffect } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { LOCAL_API_URL } from "../../config";
import arrayMove from "array-move";
import "./index.css";
import Products from "./products";
import AddCategory from "./AddCategory/add";
import DeleteCategory from "./DeleteCategory";
import { Skeleton, Button } from "antd";
import {LinkOutlined} from "@ant-design/icons"



const SortableComponent = (props) => {
  const { user, menu, inHouse, store } = props;
  const [tasks, setTasks] = useState([]);
  const [loadin, setloadin] = useState(false);

  const getData = async () => {
    setloadin(true);
    const res = await fetch(`${LOCAL_API_URL}/menu?idstore=${user}&inHouse=${inHouse}`);
    const tasks = await res.json();
    tasks.sort((a, b) =>
      a.sorting > b.sorting ? 1 : b.sorting > a.sorting ? -1 : 0
    );
    setTasks(tasks);
    setTimeout(() => {
      setloadin(false);
    }, 2000);
  };

  useEffect(() => {
    getData();
  }, []);

  const gotToLink = () => {
    console.log(store)
    const url = inHouse ? `https://wilbby.com/carta-digital/${store.slug}` : `https://wilbby.com/store/${store.city}/${store.slug}`
    window.open(
      url,
      '_blank' // <- This is what makes it open in a new window.
    );
  }
  
  const SortableItem = SortableElement(({ value }) => {
    return (
      <>
        {loadin ? (
          <Skeleton active />
        ) : (
          <>
            <li
              className="list-group-item"
              style={{ borderColor: value.snoozed ? "#F5365C" : "gainsboro" }}
            >
              <div>
                <h1>{value.name}</h1>
                <p>{value.description}</p>
                {value.snoozed ? <p>Categoría ocultada</p> : null}
              </div>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  className="imagen_prod_add_category"
                  src={value.imageUrl}
                />
                <DeleteCategory id={value._id} getData={getData} data={value} />
              </div>
            </li>
            <Products
              produts={value.products}
              user={user}
              titleCat={value.name}
              getData={getData}
              dataCategory={value}
              inHouse={inHouse}
            />
          </>
        )}
      </>
    );
  });

  const SortableList = SortableContainer(({ items }) => {
    return (
      <ul className="list-group">
        <div>
        <Button type="link" size="large" icon={<LinkOutlined />} onClick={gotToLink}>
          Ver carta
        </Button>
        <AddCategory user={user} getData={getData} menu={menu} inHouse={inHouse} />
        
        </div>
        {items.map((value, index) => (
          <SortableItem key={`item-${index}`} index={index} value={value} />
        ))}
      </ul>
    );
  });

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    let tasksCopy = [...tasks];
    tasksCopy = arrayMove(tasksCopy, oldIndex, newIndex);
    setTasks(tasksCopy);
    const tasksIds = tasksCopy.map((t) => t._id);
    await fetch(`${LOCAL_API_URL}/menu`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(tasksIds),
    });
  };

  return <SortableList items={tasks} onSortEnd={onSortEnd} />;
};

function Menu(props) {
  const { user, menu, inHouse, store } = props;

  return (
    <div className="container">
      <div className="row">
        <div>
          <h1>Categorias</h1>
          <SortableComponent user={user} menu={menu} inHouse={inHouse} store={store} />
        </div>
      </div>
    </div>
  );
}

export default Menu;
