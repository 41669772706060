import gql from "graphql-tag";

const AUTENTICAR_RESTAURANT = gql`
  mutation autenticarRestaurant($email: String, $password: String) {
    autenticarRestaurant(email: $email, password: $password) {
      message
      success
      data {
        id
        token
      }
    }
  }
`;

const UPLOAD_FILE = gql`
  mutation singleUpload($imgBlob: String) {
    singleUpload(file: $imgBlob) {
      filename
    }
  }
`;

const UPLOAD_FILE_AWS = gql`
  mutation singleUploadToAws($imgBlob: String) {
    singleUploadToAws(file: $imgBlob) {
      data
    }
  }
`;

const NEW_ORDER_PROCEED = gql`
  mutation NewOrdenProceed(
    $ordenId: String
    $status: String
    $IntegerValue: Int
    $statusProcess: OrderProccessInput
  ) {
    NewOrdenProceed(
      ordenId: $ordenId
      status: $status
      IntegerValue: $IntegerValue
      statusProcess: $statusProcess
    ) {
      messages
      success
    }
  }
`;

const SNOOZED_PRODUCT = gql`
  mutation snoozedProduct($id: ID, $snoozed: Boolean) {
    snoozedProduct(id: $id, snoozed: $snoozed) {
      messages
      success
    }
  }
`;

const CREATE_PRODUCT = gql`
  mutation createProduct($input: CreateProductInput) {
    createProduct(input: $input) {
      messages
      success
    }
  }
`;

const DELETE_PRODUCT = gql`
  mutation eliminarProducto($id: ID) {
    eliminarProducto(id: $id) {
      messages
      success
    }
  }
`;

const ACTUALIZAR_PRODUCT = gql`
  mutation actualizarProduct($input: CreateProductInput) {
    actualizarProduct(input: $input) {
      messages
      success
    }
  }
`;

const ACTUALIZAR_BUNDLED = gql`
  mutation actualizarBundled($input: CreateProductInput) {
    actualizarBundled(input: $input) {
      messages
      success
    }
  }
`;

const ADD_BUNDLED = gql`
  mutation createBundled($input: BundledInput) {
    createBundled(input: $input) {
      messages
      success
    }
  }
`;

const ADD_MODIFIELD = gql`
  mutation createModifield($input: InputModifieldGroup) {
    createModifield(input: $input) {
      messages
      success
    }
  }
`;

const ADD_MODIFIED_GROUP = gql`
  mutation createModifieldGroup($input: InputModifieldGroup) {
    createModifieldGroup(input: $input) {
      messages
      success
    }
  }
`;

const UPDATE_MODIFIED_GROUP = gql`
  mutation actualizarModifiedGroup($input: InputModifieldGroup) {
    actualizarModifiedGroup(input: $input) {
      messages
      success
    }
  }
`;

const UPDATE_MODIFIED = gql`
  mutation actualizarModified($input: InputModifieldGroup) {
    actualizarModified(input: $input) {
      messages
      success
    }
  }
`;

const CREATE_CATEGORY = gql`
  mutation createNewCategory($input: InputModifieldGroup) {
    createNewCategory(input: $input) {
      messages
      success
    }
  }
`;

const ELIMINAR_BUNDLED = gql`
  mutation eliminarBundled($id: ID) {
    eliminarBundled(id: $id) {
      messages
      success
    }
  }
`;

const ACTUALIZAR_CATEGORY = gql`
  mutation actualizarCategoria($input: InputModifieldGroup) {
    actualizarCategoria(input: $input) {
      messages
      success
    }
  }
`;


const UPDATE_CATEGORY = gql`
  mutation updateCategoria($input: InputModifieldGroup) {
    updateCategoria(input: $input) {
      messages
      success
    }
  }
`;



const DELETE_MODIFIELDGROUP = gql`
  mutation eliminarModifieldGroup($id: ID) {
    eliminarModifieldGroup(id: $id) {
      messages
      success
    }
  }
`;

const SNOOZED_MODIFIELD = gql`
  mutation snoozedModifield($id: ID, $snoozed: Boolean) {
    snoozedModifield(id: $id, snoozed: $snoozed) {
      messages
      success
    }
  }
`;

const DELETE_MODIFIELD = gql`
  mutation eliminarModifield($id: ID) {
    eliminarModifield(id: $id) {
      messages
      success
    }
  }
`;

const DELETE_CATEGORY = gql`
  mutation eliminarCategoria($id: ID) {
    eliminarCategoria(id: $id) {
      messages
      success
    }
  }
`;

const CREATE_MENU = gql`
  mutation createMenu($input: MenuInput) {
    createMenu(input: $input) {
      messages
      success
    }
  }
`;

const UPLOAD_FILE_STORE = gql`
  mutation singleUploadToStoreImagenAws($file: String) {
    singleUploadToStoreImagenAws(file: $file) {
      data
    }
  }
`;

const UPDATE_STORE = gql`
  mutation actualizarRestaurantAdmin($input: DataStore) {
    actualizarRestaurantAdmin(input: $input) {
      messages
      success
    }
  }
`;

const ACTUALIZAR_STORE = gql`
  mutation actualizarRestaurant($input: RestaurantInput) {
    actualizarRestaurant(input: $input) {
      messages
      success
    }
  }
`;

const UPDATE_RESERVA = gql`
  mutation NewReservaProceed($id: ID, $status: String) {
    NewReservaProceed(id: $id, status: $status) {
      messages
      success
    }
  }
`;

export const mutations = {
  UPDATE_STORE,
  UPLOAD_FILE_STORE,
  UPLOAD_FILE,
  UPLOAD_FILE_AWS,
  AUTENTICAR_RESTAURANT,
  NEW_ORDER_PROCEED,
  ACTUALIZAR_STORE,
  SNOOZED_PRODUCT,
  CREATE_PRODUCT,
  DELETE_PRODUCT,
  ACTUALIZAR_PRODUCT,
  CREATE_CATEGORY,
  CREATE_MENU,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  ACTUALIZAR_BUNDLED,
  ELIMINAR_BUNDLED,
  ADD_BUNDLED,
  ADD_MODIFIED_GROUP,
  ADD_MODIFIELD,
  UPDATE_MODIFIED_GROUP,
  UPDATE_MODIFIED,
  DELETE_MODIFIELDGROUP,
  DELETE_MODIFIELD,
  SNOOZED_MODIFIELD,
  UPDATE_RESERVA,
  ACTUALIZAR_CATEGORY
};
