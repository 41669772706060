export const formaterPrice = (
  price: any,
  localeCode: string,
  currecy: string
) => {
  const priceFormater = new Intl.NumberFormat(
    localeCode ? localeCode : "es-ES",
    {
      style: "currency",
      currency: currecy ? currecy : "EUR",
    }
  ).format(price);

  return priceFormater;
};
