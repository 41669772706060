import React from "react";
import moment from "moment";
import { Progress } from "antd";

const { useCallback, useEffect, useRef, useState } = React;

const calculateDuration = (eventTime: any) =>
  moment.duration(
    Math.max(eventTime - Math.floor(Date.now() / 1000), 0),
    "seconds"
  );

//@ts-ignore
export function Countdown({ eventTime, interval, scheduled, status }) {
  const [duration, setDuration] = useState(calculateDuration(eventTime));
  const timerRef = useRef(0);

  const timerCallback = useCallback(() => {
    setDuration(calculateDuration(eventTime));
  }, [eventTime]);

  useEffect(() => {
    //@ts-ignore
    timerRef.current = setInterval(timerCallback, interval);

    return () => {
      clearInterval(timerRef.current);
    };
  }, [eventTime]);

  return (
    <>
      {duration.minutes() === 0 ? (
        <>
          {status == "Entregada" || status == "Finalizada" ? (
            <Progress percent={100} size="small" />
          ) : (
            <>
              {status == "Devuelto" ||
              status == "Rechazada por la tienda" ? null : (
                <div>
                  <Progress percent={80} size="small" status="exception" />
                  <span>Entrega fuera de tiempo</span>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {status == "Entregada" || status == "Finalizada" ? (
            <Progress percent={100} size="small" />
          ) : (
            <>
              {status == "Devuelto" ||
              status == "Rechazada por la tienda" ? null : (
                <div style={{ marginTop: 10 }}>
                  {scheduled ? `${duration.hours()} Horas` : null}{" "}
                  {duration.minutes()} Minutos {duration.seconds()} Segundos
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
