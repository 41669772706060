import React, { useState } from "react";
import Logo from "../../Assets/images/icon.png";
import NormalLoginForm from "./form";
import NormalForgotForm from "./forgotForm";
import Header from "../Landing/header";
import "./index.css";

const Auth = () => {
  const [View, setView] = useState("Login");
  return (
    <div className="Login_Container">
      <Header />
      <div className="Login_Box">
        <div className="auth__box">
          <div className="auth__box-logo">
            <img src={Logo} alt="Wilbby" />
          </div>
          <div className="auth__box-form">
            {View === "Login" ? (
              <NormalLoginForm setView={setView} />
            ) : (
              <NormalForgotForm setView={setView} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
