import React, { useState } from "react";
import { Modal, Select, DatePicker, message, Spin } from "antd";
import moment from "moment";
//@ts-ignore
import locate from "moment/locale/es";
import { useQuery } from "react-apollo";
import { query } from "../../GraphQL";
import dataMunicipio from "./municipio.json";
import { LOCAL_API_URL } from "../../config";

const { Option } = Select;
const { RangePicker } = DatePicker;

const date = new Date();
let day = date.getDate();
let month = date.getMonth() + 1;
let year = date.getFullYear();

let day1 = date.getDate();
let month1 = date.getMonth() + 2;
let year1 = date.getFullYear();

const dateFormat = "MM/DD/YYYY";
const fromDate = `${month}/${day}/${year}`;
const toDate = `${month1}/${day1}/${year1}`;

export default function Report(props: any) {
  const { setIsModalVisible, isModalVisible, user } = props;

  const [DateRange, setDateRange] = useState({
    fromDate: null,
    toDate: null,
  });
  const [tipo, setTipo] = useState("Periodo");

  const [Loading, setLoading] = useState(false);

  const onDateRangeChange = (dates: any, dateStrings: any) => {
    const fromDate = dateStrings[0];
    const toDate = dateStrings[1];

    setDateRange({
      fromDate:
        tipo === "Sólo un día" ? moment(dates).format(dateFormat) : fromDate,
      toDate: tipo === "Sólo un día" ? null : toDate,
    });
  };

  function handleChange(value: string) {
    setTipo(value);
  }

  const datos = {
    city: null,
    Stores: [user._id],
    dateRange: DateRange,
    courier: [],
    tipo: tipo,
  };

  const getReport = async () => {
    setLoading(true);
    const res = await fetch(`${LOCAL_API_URL}/genetate-report`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(datos),
    });

    const report = await res.json();

    if (report.success) {
      setLoading(false);
      message.success(report.message);
      window.open(
        `${LOCAL_API_URL}/download-report?file=${report.file}`,
        "_blank"
      );
    } else {
      setLoading(false);
      message.warning(report.message);
    }
  };

  return (
    <Modal
      title={false}
      visible={isModalVisible}
      okText="Obtener reporte"
      cancelText="Cancelar"
      okButtonProps={{ disabled: DateRange.fromDate ? false : true }}
      onOk={getReport}
      onCancel={() => {
        setIsModalVisible(false);
      }}
    >
      <Spin tip="Loading..." spinning={Loading}>
        <div className="modal_contents">
          <h3 style={{ marginBottom: 25 }}>Obtener reporte de pedidos</h3>
          <Select
            allowClear
            value={tipo}
            style={{
              width: "auto",
              height: 35,
              marginRight: 10,
            }}
            placeholder="Elige un tipo"
            onChange={handleChange}
          >
            <Option value="Periodo">Periodo</Option>
            <Option value="Sólo un día">Sólo un día</Option>
          </Select>
          {tipo == "Periodo" ? (
            <RangePicker
              locale={locate}
              defaultValue={[
                moment(fromDate, dateFormat),
                moment(toDate, dateFormat),
              ]}
              format={dateFormat}
              onChange={onDateRangeChange}
              style={{ height: 35, marginRight: 10 }}
            />
          ) : (
            <DatePicker
              onChange={onDateRangeChange}
              style={{ height: 35, marginRight: 10 }}
              defaultValue={moment(fromDate, dateFormat)}
              format={dateFormat}
              locale={locate}
            />
          )}
        </div>
      </Spin>
    </Modal>
  );
}
