import React from "react";
import "./sesion.css";
import animationData from "../../../Assets/Animate/9912-payment-success.json";
import animationData1 from "../../../Assets/Animate/21934-two-friends.json";
import animationData2 from "../../../Assets/Animate/42361-marketing.json";
import Lottie from "react-lottie";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const defaultOptions1 = {
  loop: true,
  autoplay: true,
  animationData: animationData1,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const defaultOptions2 = {
  loop: true,
  autoplay: true,
  animationData: animationData2,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default function Sesion() {
  return (
    <div className="sesion1" id="ventajas">
      <h1>¿Por qué elegir Wilbby®?</h1>
      <div className="sesion1__item">
        <div className="sesion1__item_cont">
          <div className="bg">
            <Lottie options={defaultOptions} height={180} width={180} />
          </div>
          <h3>Aumenta tus ventas</h3>
          <p>
            Con la estrategia de marketing de nuestra plataforma, verás aumentar
            el volumen de pedidos de tu tienda.
          </p>
        </div>
        <div className="sesion1__item_cont">
          <div className="bg">
            <Lottie options={defaultOptions1} height={130} width={130} />
          </div>
          <h3>Encuentra nuevos clientes</h3>
          <p>Atrae a nuevos clientes y consigue que repitan y se queden.</p>
        </div>
        <div className="sesion1__item_cont">
          <div className="bg">
            <Lottie options={defaultOptions2} height={130} width={130} />
          </div>
          <h3>Automatizar el marketing</h3>
          <p>
            Ponemos todos nuestros recursos y medios a tu servicio para ayudarte
            a reducir costes e impulsar tu negocio.
          </p>
        </div>
      </div>
    </div>
  );
}
