import { useState } from "react";
import {
  Table,
  Tag,
  Space,
  Button,
  Avatar,
  Input,
  Tooltip,
  message,
  Modal,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { formaterPrice } from "../../Utils/FormaterPrice";
import { useQuery, useMutation } from "react-apollo";
import { query, mutations } from "../../GraphQL";
import AddProduct from "./add";
import EditProduct from "./EditProducto";
import "./index.css";

const { Search } = Input;
const { confirm } = Modal;

export default function Products(props: any) {
  const [search, setsearch] = useState("");
  const [Loading, setLoading] = useState(false);
  const [visible, setvisible] = useState(false);
  const [dataDetails, setdataDetails] = useState(null);
  const [isd, setisd] = useState("");
  const [snoozedProduct] = useMutation(mutations.SNOOZED_PRODUCT);
  const { user, fromMenu } = props;
  const [eliminarProducto] = useMutation(mutations.DELETE_PRODUCT);
  const { data, refetch, loading } = useQuery(query.GET_PRODUCT_BUNDLED, {
    variables: { store: user },
  });

  const onSearch = (value: string) => setsearch(value);

  const setDetalles = (prod: any) => {
    setdataDetails(prod);
    setvisible(true);
  };

  const eliminarProduct = (id: string) => {
    setisd(id);
    setLoading(true);
    eliminarProducto({ variables: { id: id } })
      .then((res) => {
        if (res.data.eliminarProducto.success) {
          setLoading(false);
          message.success(res.data.eliminarProducto.messages);
          refetch();
        } else {
          setLoading(false);
          message.warning(res.data.eliminarProducto.messages);
          refetch();
        }
      })
      .catch(() => {
        setLoading(false);
        message.error("Algo salio mal intentalo de nuevo");
      });
  };

  function showConfirm(id: string) {
    confirm({
      title: "¿Estás seguro que deseas eliminar este producto?",
      icon: <ExclamationCircleOutlined />,
      content:
        "Si lo elimina no podrás recuperar los datos tambien puede ponerlo como no visible ",
      okText: "Eliminar producto",
      cancelText: "Cancelar",
      onOk() {
        eliminarProduct(id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  const products =
    data && data.getNewProductoBundled ? data.getNewProductoBundled.data : [];

  refetch();

  const snoozedProducts = (snoozed: boolean, id: string) => {
    snoozedProduct({ variables: { id: id, snoozed: snoozed } })
      .then((res) => {
        if (res.data.snoozedProduct.success) {
          message.success("Producto marcado como agotado o deshabilitado");
          refetch();
        } else {
          message.success("Algo salio mal intentalo de nuevo");
          refetch();
        }
      })
      .catch(() => {
        message.success("Algo salio mal intentalo de nuevo");
      });
  };

  const columns = [
    {
      key: "name",
      title: "Nombre",
      render: (data: any) => {
        return (
          <Space size="middle">
            <Avatar shape="square" size={50} src={data.imageUrl} />
            <h3 className="name_product">{data.name}</h3>
          </Space>
        );
      },
    },
    {
      key: "price",
      title: "Precio",
      render: (data: any) => {
        return <p>{formaterPrice(data.price / 100, "", "")}</p>;
      },
    },
    {
      key: "description",
      title: "Decripción",
      render: (data: any) => {
        return <p className="name_product">{data.description}</p>;
      },
    },

    {
      key: "account",
      title: "Disponibilidad",
      render: (snoozed: any) =>
        snoozed.snoozed ? (
          <Tag color="red">Agotado</Tag>
        ) : (
          <Tag color="lime">Disponible</Tag>
        ),
    },
    {
      key: "_id",
      title: "Acción",
      render: (data: any) => (
        <Space size="middle">
          <Tooltip title="Editar producto">
            <Button
              icon={<EditOutlined />}
              type="primary"
              onClick={() => setDetalles(data)}
            />
          </Tooltip>
          <Tooltip title="Eliminar producto">
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => showConfirm(data._id)}
              loading={isd === data._id && Loading}
            />
          </Tooltip>
          {data.snoozed ? (
            <Tooltip title="No Visible">
              <Button
                type="dashed"
                icon={<EyeInvisibleOutlined />}
                onClick={() => snoozedProducts(false, data._id)}
              />
            </Tooltip>
          ) : (
            <Tooltip title="visible">
              <Button
                type="dashed"
                icon={<EyeOutlined />}
                onClick={() => snoozedProducts(true, data._id)}
              />
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      <Space
        size="large"
        align="center"
        style={{ marginBottom: 20, marginLeft: fromMenu ? 20 : 0 }}
      >
        <Space size="middle">
          <AddProduct
            store={user}
            refetch={refetch}
            bundled={true}
            fromList={true}
          />
        </Space>
      </Space>
      <Table columns={columns} dataSource={products} loading={loading} />
      {dataDetails ? (
        <EditProduct
          datas={dataDetails}
          refetch={refetch}
          visible={visible}
          setVisible={setvisible}
          user={user}
          setdataDetails={setdataDetails}
          bundled={true}
        />
      ) : null}
    </div>
  );
}
