import React from "react";
import "./index.css";
import { formaterPrice } from "../../../Utils/FormaterPrice";

export default function Items(props: any) {
  const { data, title } = props;

  return (
    <div className="items-cart">
      <h3>{title}</h3>

      <div className="items-cart-horario">
        <p style={{ color: "gray" }}>
          {data ? data.cuponName : "No disponible"}
        </p>
        <p style={{ color: "gray" }}>{data ? data.cuponTipe : "porcentaje"}</p>
        {data.cuponTipe === "porcentaje" ? (
          <p>{data.cuponValue}%</p>
        ) : (
          <p>{formaterPrice(data.discountTotal / 100, "es-ES", "EUR")}</p>
        )}
      </div>
    </div>
  );
}
