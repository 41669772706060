import React from "react";
import Logo from "../../../Assets/images/logowhite.svg";
import LogoSmall from "../../../Assets/images/logoblack.png";
import "./header.css";
import { Link } from "react-router-dom";
import $ from "jquery";

$(function () {
  $(window).scroll(function () {
    if ($(this).scrollTop() > 30) {
      $(".nav").addClass("shadow");
    } else {
      $(".nav").removeClass("shadow");
    }
  });
});

export default function Header() {
  return (
    <div className="nav">
      <div className="cont">
        <Link to="/">
          <img src={LogoSmall} alt="Wilbby" className="logo" />
          <img src={Logo} alt="Wilbby" className="logosmall" />
        </Link>
        <div className="cont_rigth">
          <ul>
            <li className="links">
              <a href="https://blog.wilbby.com" target="_black">
                Blog
              </a>
            </li>
            <li className="links">
              <a href="https://wilbby.com" target="_black">
                Sobre Wilbby
              </a>
            </li>
            {localStorage.getItem("token") ? (
              <li>
                <Link to="/dashboard" className="btn_Login">
                  Ir al Dashboard
                </Link>
              </li>
            ) : (
              <>
                <li className="links">
                  <a href="#unirme" className="btn_Login">
                    ¡Únete a Wilbby Gratis!
                  </a>
                </li>
                <li>
                  <Link to="/login" className="btn_Login">
                    Iniciar sesión
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}
