import React, { useState } from "react";
import "./index.css";
import {
  message,
  Modal,
  Input,
  Tooltip,
  Upload,
  Switch,
  Button,
  InputNumber,
} from "antd";
import { PlusCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { mutations } from "../../GraphQL";
import { useMutation, Mutation } from "react-apollo";
import { DeleteOutlined } from "@ant-design/icons";

function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default function AddModifield(props: any) {
  const { store, refetch, bundled, fromList } = props;
  const [visible, setVisible] = useState(false);
  const [isNew, setisNew] = useState(false);
  const [isPopular, setisPopular] = useState(false);
  const [isOffert, setisOffert] = useState(false);
  const [title, settitle] = useState("");
  const [imagen, setimagen] = useState("");
  const [loadingImage, setloadingImage] = useState(false);
  const [price, setprice] = useState(0);
  const [max, setmax] = useState(1);
  const [min, setmin] = useState(1);
  const [mulmax, setmulmax] = useState(1);
  const [mulmin, setmulmin] = useState(1);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [recomended, setrecomended] = useState(false);
  const [snoozed, setsnoozed] = useState(false);

  const [createModifield] = useMutation(mutations.ADD_MODIFIELD);

  const uploadButton = (
    <Tooltip title="Añadir foto">
      <div style={{height: 230, display: "grid", alignItems: "center"}}>
        <div className="ant-upload-text">
        {loadingImage ? (
          <LoadingOutlined style={{ fontSize: 30, color: "#90c33c" }} />
        ) : (
          <PlusCircleOutlined style={{ fontSize: 30, color: "#90c33c" }} />
        )}
          <p>Añadir foto</p>
        </div>
      </div>
    </Tooltip>
  );

  const showModal = () => {
    setVisible(true);
  };

  const newPrice = price;

  const input = {
    name: title,
    description: "",
    account: store,
    location: store,
    productType: 1,
    plu: "",
    price: Number(newPrice.toFixed(0)),
    sortOrder: 0,
    deliveryTax: 0,
    takeawayTax: 0,
    multiply: mulmin,
    multiMax: mulmax,
    posProductId: "",
    posProductCategoryId: [],
    subProducts: [],
    productTags: [],
    posCategoryIds: [],
    imageUrl: imagen,
    max: max,
    min: min,
    capacityUsages: [],
    parentId: "",
    visible: true,
    snoozed: snoozed,
    subProductSortOrder: [],
    recomended: recomended,
    quantity: 1,
    new: isNew,
    popular: isPopular,
    offert: isOffert,
    previous_price: 0,
    storeId: store,
  };

  const handleOk = () => {
    setConfirmLoading(true);
    createModifield({ variables: { input: { data: input } } })
      .then((res) => {
        if (res.data.createModifield.success) {
          message.success("Modificador añadido con éxito");
          refetch();
          setConfirmLoading(false);
          setVisible(false);
        } else {
          message.warning("Algo salio mal intentalo de nuevo");
          refetch();
          setConfirmLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        message.error("Algo salio mal intentalo de nuevo");
        refetch();
        setConfirmLoading(false);
      });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  function onChangeRecomended(checked: boolean) {
    setrecomended(checked);
  }

  function onChangeSnoozed(checked: boolean) {
    setsnoozed(checked);
  }

  function onChangeMax(value: any) {
    setmax(value);
  }

  function onChangeMin(value: any) {
    setmin(value);
  }

  function onChangemulMax(value: any) {
    setmulmax(value);
  }

  function onChangemulMin(value: any) {
    setmulmin(value);
  }

  const isOk = () => {
    if (title) {
      return false;
    } else {
      return true;
    }
  };

  const onchangeTitle = (e: any) => {
    e.preventDefault();
    settitle(e.target.value);
  };

  return (
    <>
      <Button
        onClick={() => showModal()}
        type={bundled && !fromList ? "dashed" : "primary"}
        icon={<PlusCircleOutlined />}
        style={{
          height: bundled && !fromList ? 32 : 40,
          marginTop: bundled && !fromList ? 10 : 0,
        }}
      >
        {bundled ? "Crear modificador" : "Añadir modificador"}
      </Button>

      <Modal
        title="Añadir modificador"
        visible={visible}
        onOk={handleOk}
        okText="Añadir modificador"
        cancelText="Cancelar"
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okButtonProps={{ disabled: isOk() }}
      >
        <div className="content_modal">
          <p>Foto del modificador</p>
          <Mutation mutation={mutations.UPLOAD_FILE_AWS}>
            {(singleUploadToAws: any) => (
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={async (data) => {
                  setloadingImage(true);
                  let imgBlob = await getBase64(data.file);
                  singleUploadToAws({ variables: { imgBlob } })
                    .then((res: any) => {
                      setloadingImage(false);
                      setimagen(res.data.singleUploadToAws.data.Location);
                    })
                    .catch((error: any) => {
                      setloadingImage(false);
                      message.warning(
                        "Imagen demaciado grande intenta con una más pequeña"
                      );
                    });
                }}
              >
                {imagen ? (
                  <Tooltip title="Haz click para cambiar">
                    <img className="imagen_prod_add" src={imagen} />
                  </Tooltip>
                ) : null}

                {!imagen ? uploadButton : null}
              </Upload>
            )}
          </Mutation>

          {imagen ? (
            <Button
              onClick={() => setimagen("")}
              icon={<DeleteOutlined />}
              type="primary"
              danger
              style={{ width: 200, marginTop: 10 }}
            >
              Eliminar imagen
            </Button>
          ) : null}
          <br />

          <Button
            type="primary"
            style={{ width: 200, marginTop: 10 }}
            href="https://www.iloveimg.com/es/comprimir-imagen"
            target="_blank"
          >
            Comprimir imagen
          </Button>

          <div className="add_info">
            <h4>Información del modificador</h4>
            <p>
              Nombre del modificador <span style={{ color: "red" }}>*</span>
            </p>
            <Input
              placeholder="Nombre del modificador"
              onChange={onchangeTitle}
            />

            <div className="btn_contents">
              <div style={{ width: "100%" }}>
                <p>Precio de venta</p>
                <InputNumber
                  style={{ width: bundled ? "48%" : "98%", marginTop: 8 }}
                  placeholder="Nuevo precio de venta"
                  min={0}
                  type="number"
                  onChange={(value: any) => setprice(Number(value.replace(/\,/, '.')) * 100)}
                />
              </div>
            </div>

            <div className="btn_contents">
              <InputNumber
                min={1}
                max={100}
                value={min}
                style={{ width: "49%" }}
                placeholder="Minímo"
                type="number"
                onChange={onChangeMin}
              />
              <InputNumber
                min={1}
                max={100}
                value={max}
                style={{ width: "51%" }}
                placeholder="Maximo"
                type="number"
                onChange={onChangeMax}
              />
            </div>

            <div className="btn_contents">
              <InputNumber
                min={1}
                max={100}
                value={mulmin}
                style={{ width: "49%" }}
                placeholder="Minímo multiplicable"
                onChange={onChangemulMin}
              />
              <InputNumber
                min={1}
                max={100}
                value={mulmax}
                type="number"
                style={{ width: "51%" }}
                placeholder="Maximo multiplicable"
                onChange={onChangemulMax}
              />
            </div>

            <div className="btn_contents">
              <div className="btn_item popu">
                <p>Recomendado</p>
                <Switch
                  defaultChecked={recomended}
                  onChange={onChangeRecomended}
                  className={recomended ? "popu" : "popu"}
                />
              </div>
              <div className="btn_item new">
                <p>Agotado</p>
                <Switch
                  defaultChecked={snoozed}
                  onChange={onChangeSnoozed}
                  className={snoozed ? "New" : "NoNew"}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
